import React, { useState } from 'react'
import { toast } from 'react-toastify';
// import { assignPermission, retirePermission } from '../../../services/UserService';

const CheckoutItem = ({ checkout,checked, setCheckoutSelected }) => {
  const [selected, setSelected] = useState(checked)
  const notify = (message) => toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });

  const toggleChecked = (idChecked) => {
    if (selected) {
      setCheckoutSelected(currentValue => {
        const copyCurrentValue = [...currentValue]
        const index = copyCurrentValue?.findIndex(element => element.id == idChecked)
        copyCurrentValue.splice(index,1)
        return copyCurrentValue
      })
      setSelected(current => !current)
      return
    }
    setCheckoutSelected(currentValue => {
      const copyCurrentValue = [...currentValue]
      copyCurrentValue.push(idChecked)
      return copyCurrentValue
    })
    setSelected(current => !current)
  }
  

  return (
    <div className="form-check d-flex align-items-center g-col-2 custom-checkbox mb-3">
      <input
        type="checkbox"
        className="form-check-input"
        id={`permission-${checkout.id}${checkout.reference}`}
        checked={selected}
        onChange={(e) => toggleChecked(checkout.id)}
      />
      <label
        className="form-check-label"
        htmlFor={`permission-${checkout.id}${checkout.reference}`}
        
      >
        <div style={{marginLeft: "5px"}}>
        <strong>Raison : </strong>  {checkout.raison}<br/>
        <strong>Prix Total : </strong>  {checkout.prixTotal}
        </div>
      </label>
      
    </div>
  );
};

export default CheckoutItem