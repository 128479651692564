import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Pusher from "pusher-js";

import beep from "../../../../sounds/phone-call-14472.mp3";
import { createCall } from "../../../../services/CallService";
import Peer from "peerjs";
import { peerData } from "../../../../data/peerjs";
import Echo from "laravel-echo";

function Dial({ show, user, startCall, stopCall, cancelCall }) {
  const client = localStorage.getItem("clientDetails");
  const [callID, setCallID] = useState(null);
  const [creatingCall, setCreatingCall] = useState(false);
  const [createdCall, setCreatedCall] = useState(false);
  const [reponse, setReponse] = useState(false);
  const [peer, setPeer] = useState(false);
  const audioRef = useRef(null);

  const sendCall = (user, client_details) => {
    setCreatingCall(true);

    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then(function (mediaStream) {
        const peer = new Peer(peerData);
        setPeer(peer);

        peer.on("open", function (id) {
          createCall({
            caller: client_details.client.id,
            called: user.id,
            called_type: "admin",
            caller_type: "client",
            peerid: id,
          })
            .then(({ data }) => {
              setCallID(data.call_id);
            })
            .finally(() => setCreatingCall(false));
        });

        peer.on("call", function (call) {
          call.answer(mediaStream);

          call.on("stream", function (remoteStream) {
            setCreatedCall(true);
            audioRef.current.srcObject = remoteStream;
          });
        });
      })
      .catch(function (error) {
        console.error("Failed to get user media:", error);
      });
  };

  const destroyConnetion = () => {
    if (peer) {
      audioRef.current.srcObject = null;
      peer.destroy();
    }
  }

  const stop = () => {
    stopCall(callID);
    destroyConnetion();
  };

  const cancel = () => {
    cancelCall(callID);
    destroyConnetion();
  }

  useEffect(() => {
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: process.env.REACT_APP_MIX_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_MIX_PUSHER_APP_CLUSTER,
      encrypted: true,
    });

    const channel = window.Echo.channel("calling");
    const channelCancelCall = window.Echo.channel("cancel-call");
    const channelAcceptCall = window.Echo.channel("answer-call");
    const channelStopCall = window.Echo.channel("stop-call");
    const client = JSON.parse(localStorage.getItem("clientDetails"));

    if (client) {
      channel
        .subscribed(() => {
          // console.log(client.client)
          console.log("subscribed");
        })
        .listen(".new_call", (e) => {
          // console.log(e);
          if (e.called.id === client.client.id) {
            console.log("Appel entrant venant de " + e.caller.name);
          }
        });

      channelCancelCall
        .subscribed(() => {
          console.log("subscribed cancel-call channel");
        })
        .listen(".cancel_call", (e) => {
          if (
            e.caller.id === client.client.id ||
            e.called.id === client.client.id
          ) {
            stopCall();
          }
        });

      channelAcceptCall
        .subscribed(() => {
          console.log("subscribed answer-call channel");
        })
        .listen(".answer_call", (e) => {
          if (
            e.called.id === client.client.id ||
            e.caller.id === client.client.id
          ) {
            setReponse(true);
          }
        });

      channelStopCall
        .subscribed(() => {
          console.log("subscribe stop-call channel");
        })
        .listen(".stop.call", (e) => {
          if (
            e.caller.id === client.client.id ||
            e.called.id === client.client.id
          ) {
            stopCall();
          }
        });
    }
  }, []);

  useEffect(() => {
    if (user && client) {
      const client_details = JSON.parse(client);
      sendCall(user, client_details);
    }
  }, [user]);

  useEffect(() => {
    if (callID) {
      startCall();
    }
  }, [callID])

  return (
    <Modal show={show} onHide={stopCall}>
      <Modal.Body
        style={{ backgroundColor: "#1E1E1E" }}
        className="text-white rounded"
      >
        <audio ref={audioRef} autoPlay />
        <div className="d-flex flex-column align-items-center">
          <img
            src="https://img.freepik.com/free-icon/user_318-159711.jpg"
            className="rounded-circle mb-3 p-2"
            style={{ border: "1px solid #FFF4" }}
            alt=""
            height={120}
            width={120}
          />
          <h4 className="text-white">{user?.name}</h4>
        </div>
        <div className="text-center pb-5" style={{ opacity: 0.7 }}>
          {creatingCall
            ? "Connexion..."
            : createdCall
            ? ""
            : "Attente de réponse"}
        </div>
        <div className="mt-5 d-flex justify-content-center">
          <Button
            className="d-flex align-items-center justify-content-center rounded-circle me-3"
            variant="outline-dark"
            style={styles.buttons}
          >
            <i className="fa fa-microphone-slash text-white"></i>
          </Button>
          {/* <Button
            className="d-flex align-items-center justify-content-center rounded-circle me-3"
            variant="outline-dark"
            style={styles.buttons}
          >
            <i className="fa fa-video-slash text-white"></i>
          </Button> */}
          <Button
            className="d-flex align-items-center justify-content-center rounded-circle"
            variant="primary"
            style={styles.buttons}
            onClick={reponse ? stop : cancel}
          >
            <i className="fa fa-phone text-white"></i>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Dial;

const styles = {
  buttons: {
    width: "60px",
    height: "60px",
  },
};
