import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { deleteEquipment } from '../../../services/ChambreService';

const ToastOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
}

function DeleteEquipment({ equipment, handleEquipment, handleClose }) {
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    swal({
      title: "Êtes-vous sûr?",
      text: "Cette action sera irréversible!",
      icon: "warning",
      buttons: {
        cancel: "Annuler",
        confirm: "Supprimer",
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        deleteEquipment(equipment.id).then(() => {
          toast.success(`Élément supprimé`, ToastOption);
          handleClose();
          handleEquipment();
        }).finally(() => setLoading(false))
      }
    });
  };

  return (
    <Button variant="outline-primary" size="sm" onClick={handleDelete} disabled={loading}>
      <i className="fa fa-trash me-2"></i>
      <span>Supprimer</span>
    </Button>
  )
}

export default DeleteEquipment