import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

const Graphes = ({type, data}) => {
  

  return (<Chart type={ type} data={data} />);
  
};

export default Graphes;
