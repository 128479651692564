import React from "react";
import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";

// componnents
import BreadCrumb from "./BreadCrumb";
import ArticleContent from "./ArticleContent";

// data
import { documenationData } from "../../../data/documenationGuide";

function MainDocumentation() {
    const [uri] = useState(window.location.pathname.slice(15));
    // state
    const [guides, setGuides] = useState(undefined);
    const [data, setData] = useState(documenationData[uri.split('/')[0]]);

    // clients data
    useEffect(() => {
        setGuides(
            data.find( client => client.uri === uri)?.guide
        );
    }, [uri]);
    

    return(
        <div>
           <BreadCrumb path_segment={ uri } />

            <div className="card w-100 py-5 px-4">
                <Container className="mt-0">

                    {
                        guides === undefined ? (
                            <p>Chargement...</p> 
                        ): 
                        (
                            <>
                                {   guides.mainTitle && (<h2>{ guides.mainTitle}</h2>)    }

                                {   guides.description && (<h6>{ guides.description }</h6>)   }
                                
                                { 
                                    guides.map( (guide, index) => (
                                        <ArticleContent key={index}
                                            title={guide.title}
                                            paragraph={guide.paragraph}
                                            src={guide.illustration}
                                        />
                                    ))
                                }
                            </>
                        )
                    }
                </Container> 
            </div>
        </div>
    );
}

export default MainDocumentation
