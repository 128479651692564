export const articles = [
  {
    name : "Comptes",
    url: "documentation/compte",
    pages : [
      {
        title: "Ajouter un compte",
        url: "documentation/compte/ajout",
      },
      {
        title: "Modifier un compte et son rôle",
        url: "documentation/compte/modifier",
      },
    ],
  },
  {
    name : "Rôles",
    url: "documentation/role",
    pages : [
      {
        title: "Ajouter un rôle",
        url: "documentation/role/ajout",
      },
      {
        title: "Attribuer et voir la liste de permission",
        url: "documentation/role/permission",
      },
    ],
  },
  {
    name : "Clients",
    url: "documentation/client",
    pages : [
      {
        title: "Ajouter un client",
        url: "documentation/client/ajout",
      },
      {
        title: "Modifier les informations d'un client",
        url: "documentation/client/modification",
      },
      {
        title: "Informations d'un client",
        url: "documentation/client/information",
      },
      {
        title: "Sessions d'un client",
        url: "documentation/client/sessions",
      },
    ],
  },
  {
    name : "Chambres",
    url: "documentation/chambre",
    pages : [
      {
        title: "Ajouter un chambre",
        url: "documentation/chambre/ajout",
      },
      {
        title: "Ajouter un équipement",
        url: "documentation/chambre/equipement",
      },
      {
        title: "Que mettre sur les champs icones?",
        url: "documentation/chambre/icones",
      },
    ],
  },
]