import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import { editOrder, getDetailOrder } from "../../../services/OrderService";
import { getAllChambres } from "../../../services/ChambreService";
import { getClientsAll } from "../../../services/ClientService";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";

const defaultState = {
  room: null,
  day: 0,
  client: null,
  date_order: null,
  arrival: null,
  departure: null,
  status: 0,
  note: null,
};

const STATUS = [
  { value: 0, label: "En cours" },
  { value: 1, label: "Completé" },
];

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

function Edit() {
  const [state, setState] = useState(defaultState);
  const [rooms, setRooms] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const handleField = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRooms = () => {
    getAllChambres().then(({ data }) => {
      setRooms(data);
    });
  };

  const getData = () => {
    getDetailOrder(params.id).then(({ data }) => {
      setState({
        arrival: data.date_arrivee,
        client: data.client_id,
        date_order: data.date_reservation,
        day: data.nombre_jours,
        departure: data.date_depart,
        room: data.chambre_id,
        status: data.statut,
        note: data.note,
      });
      setLoading(false);
      handleRooms();
      handleClients();
    });
  };

  const handleClients = () => {
    getClientsAll().then(({ data }) => {
      setClients(data);
    });
  };

  const sendData = (e) => {
    e.preventDefault();
    editOrder(
      {
        chambre_id: state.room,
        client_id: state.client,
        date_reservation: state.date_order,
        date_arrivee: state.arrival,
        date_depart: state.departure,
        nombre_jours: state.day,
      },
      params.id
    )
      .then(() => {
        toast.success(`La reservation a été modifié`, toastOptions);
      })
      .catch(() => toast.error(`Erreur pendant la modification`, toastOptions));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Card>
        <Card.Body>
          <h4 className="mb-4">Modifier la reservation</h4>
          {loading ? (
            <Loading color="var(--primary)" />
          ) : (
            <form className="d-block" onSubmit={sendData}>
              <Row>
                <Col md={6} sm={12}>
                  <div className="form-group mb-4">
                    <label>Chambre</label>
                    <select
                      value={state.room}
                      name="room"
                      className="form-control w-100"
                      onChange={(e) => handleField("room", e.target.value)}
                      required
                    >
                      {rooms.map((item, index) => (
                        <option value={item.id} key={index}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <div className="form-group mb-4">
                    <label>Nombre de jours / nuits</label>
                    <input
                      type="number"
                      name="jours"
                      className="form-control"
                      onChange={(e) => handleField("day", e.target.value)}
                      value={state.day}
                      required
                    />
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <div className="form-group mb-4">
                    <label>Client</label>
                    <select
                      value={state.client}
                      name="client"
                      className="form-control w-100"
                      onChange={(e) => handleField("client", e.target.value)}
                      required
                    >
                      {clients.map((item, index) => (
                        <option value={item.id} key={index}>
                          {`${item.lastname} ${item.firstname}`}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <div className="form-group mb-4">
                    <label>Date de réservation</label>
                    <input
                      type="datetime-local"
                      name="date_order"
                      className="form-control"
                      onChange={(e) =>
                        handleField("date_order", e.target.value)
                      }
                      value={state.date_order}
                      required
                    />
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <div className="form-group mb-4">
                    <label>Date d’arrivée</label>
                    <input
                      type="datetime-local"
                      name="arrival"
                      className="form-control"
                      onChange={(e) => handleField("arrival", e.target.value)}
                      value={state.arrival}
                      required
                    />
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <div className="form-group mb-4">
                    <label>Date départ</label>
                    <input
                      type="datetime-local"
                      name="departure"
                      className="form-control"
                      onChange={(e) => handleField("departure", e.target.value)}
                      value={state.departure}
                      required
                    />
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="form-group mb-4">
                    <label>Status</label>
                    <select
                      value={state.status}
                      name="status"
                      className="form-control w-100"
                      onChange={(e) => handleField("status", e.target.value)}
                      required
                    >
                      {STATUS.map((item, index) => (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="form-group mb-5">
                    <label>Notes</label>
                    <textarea
                      className="form-control"
                      rows="6"
                      name="note"
                      value={state.note}
                      onChange={(e) => handleField("note", e.target.value)}
                    ></textarea>
                  </div>
                </Col>
              </Row>
              <Button variant="secondary" type="submit" className="w-100">
                <i className="fa fa-check me-3"></i>
                <span>Modifier</span>
              </Button>
            </form>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default Edit;
