import { createFormData } from "../helpers/services";
import axiosInstance from "./AxiosInstance";
import axios from "axios";


export function getIP() {
  return axiosInstance.get(`wifi/ip/list`);
}
export function chekingWifi() {
    return axiosInstance.get(`wifi/ckecking`);
}

export function createWifiUser(data) {
    return axiosInstance.post(`wifi/new/user`, createFormData(data));

}
export function connect(data) {
    return axiosInstance.post(`wifi/connect`, createFormData(data));
}

export function getUserById(user_id)
{
    return axiosInstance.get(`wifi/get/user/${user_id}`);
}

export function loginWifi(data) {
    return axiosInstance.post(`wifi/user/login`, createFormData(data));
}

export function loginToken(token)
{
    return axiosInstance.get(`wifi/user/login/token/${token}`);
}

export function checkMacAddress(mac) {
    return axiosInstance.get(`wifi/user/mac/${mac}`);
}

export function getWifiAllUsers() {
    return axiosInstance.get(`wifi/user/all`);
}
export function getWifiUserCount() {
    return axiosInstance.get(`wifi/user/count`);
}
export function getWifiUser(id) {
    return axiosInstance.get(`wifi/user/${id}`);
}

export function updateWifiUser(data) {
    return axiosInstance.post(`wifi/user/patch/${data.id}`, createFormData(data));
}
export function deleteWifiUser(id) {
    return axiosInstance.delete(`wifi/user/${id}`);
}

export function activateWifiUser(id) {
    return axiosInstance.post(`wifi/user/activate/${id}`);
}

export function desactivateWifiUser(id) {
    return axiosInstance.post(`wifi/user/desactivate/${id}`);
}

export function generatePassword(data) {
    return axiosInstance.post(`wifi/generer/password`, createFormData(data));
}

export function deleteUser(id) {
    return axiosInstance.post(`wifi/delete/user`, createFormData({id: id}));
}
export function getPasswordWifiForUser(id) {
    return axiosInstance.get(`wifi/password/${id}`);
}

export function getImageQrCode(password) {
    return axiosInstance.get(`wifi/image-qr-code?password=${password}`);
}

export function getUserWifi()
{
    return axiosInstance.get("wifi/get-user-wifi");
}

export function getHistoriqueWifi(client_id)
{
    return axiosInstance.get("wifi/get-historique-wifi/"+client_id);
}


export function getListSsid ()  {
    return axiosInstance.get("wifi/list/ssid/");
}

export function getSsid (id)  {
    return axiosInstance.get("wifi/ssid/"+id);
}

export function createSsid (data)  {
    return axiosInstance.post("wifi/create/ssid", createFormData(data));
}

export function editSsid (id, data)  {
    return axiosInstance.post("wifi/edit/ssid/"+id, createFormData(data));
}