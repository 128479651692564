import React from "react";
import { Accordion, Button, Col, Modal, Row } from "react-bootstrap";
import {
  getPermissions,
  getRoles,
  removeRole,
} from "../../../services/UserService";
import { roleHasPermission } from "../../../helpers/users";
import swal from "sweetalert";
import Permission from "./Permission";
import Loading from "../Loading";
import CheckPermission from "../../../templates/components/CheckPermission/CheckPermission";

const Delete = ({ role, handleRoles }) => {
  const handleDelete = () => {
    swal({
      title: "Êtes-vous sûr?",
      text: "Une fois supprimé, certains utilisateurs doivent être attribué à un autre rôle!",
      icon: "warning",
      buttons: {
        cancel: "Annuler",
        confirm: "Supprimer",
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        removeRole(role).then(() => {
          swal(`Le rôle ${role.name} a été supprimé!`, {
            icon: "success",
          });
          handleRoles();
        });
      }
    });
  };

  return (
    <CheckPermission permission="delete_role">
      <Button
        type="button"
        variant="outline-danger"
        size="sm"
        onClick={handleDelete}
      >
        <i className="fa fa-trash me-2"></i> Supprimer
      </Button>
    </CheckPermission>
  );
};

const RoleItem = ({ role, index, permissions, handleRoles }) => {
  return (
    <Accordion.Item className="accordion-item" eventKey={`${index}`}>
      <Accordion.Header className="accordion-header rounded">
        <strong>{role.name}</strong>
      </Accordion.Header>
      <Accordion.Collapse eventKey={`${index}`}>
        <div className="accordion-body">
          <div
            className="d-flex align-items-center mb-3"
            style={{ justifyContent: "space-between" }}
          >
            <h5 className="mb-0">Permissions</h5>
            <div>
              <Delete role={role} handleRoles={handleRoles} />
            </div>
          </div>
          <Row>
            {permissions.map((permission, j) => (
              <Col sm={6} key={j}>
                <Permission
                  role={role}
                  permission={permission}
                  active={roleHasPermission(permission, role.permissions)}
                  handleRoles={handleRoles}
                />
              </Col>
            ))}
          </Row>
        </div>
      </Accordion.Collapse>
    </Accordion.Item>
  );
};

function Roles({ open, closeModal }) {
  const [roles, setRoles] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const handleRoles = () => {
    setLoading(true);
    return getRoles()
      .then(({ data }) => {
        setRoles(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePermissions = () =>
    getPermissions().then(({ data }) => {
      setPermissions(data);
    });

  React.useEffect(() => {
    handlePermissions();
  }, []);

  React.useEffect(() => {
    handleRoles();
  }, [open]);

  return (
    <Modal
      className="fade bd-example-modal-lg"
      show={open}
      size="lg"
      onHide={() => closeModal()}
    >
      <Modal.Header>
        <Modal.Title>Rôles</Modal.Title>
        <Button
          variant=""
          className="btn-close"
          onClick={() => closeModal()}
        ></Button>
      </Modal.Header>
      <Modal.Body>
        <Accordion className="accordion accordion-primary">
          {roles.map((role, index) => (
            <RoleItem
              key={index}
              role={role}
              index={index}
              permissions={permissions}
              handleRoles={handleRoles}
            />
          ))}
          {roles.length === 0 ? (
            <div className="text-center">Aucun rôle enregistré</div>
          ) : (
            <></>
          )}
        </Accordion>
        {loading ? <Loading color="var(--primary)" /> : <></>}
      </Modal.Body>
    </Modal>
  );
}

export default Roles;
