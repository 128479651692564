import React from "react";
import { Link } from "react-router-dom";
import Actions from "./Actions";
import { asset_img } from "../../../helpers/data";
import Loading from "../Loading";

import default_img from "../../../images/avatar.svg";
import { formatFullDate } from "../../../helpers/dates";
import { formatPrices } from "../../../helpers/numbers";
import { getStatus } from "../../../helpers/reservations";

function List({
  chackboxFun,
  data,
  loading,
  sort,
  setStatus,
  reservation_counts,
  currentPage,
  pagination,
  setReservationCounts,
}) {
  const getColorStatus = (status) => {
    switch (status) {
      case "En cours":
        return "warning";
      case "Payé":
        return "success";
      case "Annulée":
        return "danger";
      case "Cloturé":
        return "primary";
      case "En litige":
        return "secondary";
      default:
        return "secondary";
    }
  };
  return (
    <div className="table-responsive">
      <div id="example2_wrapper" className="dataTables_wrapper no-footer">
        <table
          id="example2"
          className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
        >
          <thead>
            <tr role="row">
              <th className="sorting_asc bg-none">
                <div className="form-check  style-1">
                  <input
                    type="checkbox"
                    onClick={() => chackboxFun("all")}
                    className="form-check-input"
                    id="checkAll"
                    required=""
                  />
                </div>
              </th>
              <th className="sorting_asc">Client</th>
              <th className="sorting">Date</th>
              <th className="sorting">Réference</th>
              <th className="sorting">Total</th>
              <th className="sorting">Payé</th>
              <th className="sorting">Solde</th>
              <th className="sorting">Status</th>
              <th className="">Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading ? (
              data && data.length ? (
                data.map((item, index) => (
                  <tr
                    role="row"
                    className={index % 2 === 0 ? "odd" : "even"}
                    key={index}
                  >
                    <td className="sorting_1">
                      <div className="form-check  style-1">
                        <input
                          type="checkbox"
                          onClick={() => chackboxFun()}
                          className="form-check-input"
                          id="customCheckBox2"
                          required=""
                        />
                      </div>
                    </td>
                    <td>
                      <div className="concierge-bx d-flex align-items-center">
                        <img
                          className="me-3 rounded"
                          src={
                            item.client_photo
                              ? asset_img(item.client_photo)
                              : default_img
                          }
                          alt=""
                          style={{ objectFit: "cover" }}
                        />
                        <div>
                          <h5 className="fs-16 mb-0 text-nowrap">
                            <Link
                              to={`/client/${item.client_id}`}
                              className="text-black"
                            >
                              {`${item.client}`}
                            </Link>
                          </h5>
                          <span className="text-primary fs-14">
                            #{item.client_ref}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-nowrap">
                      <span>{formatFullDate(item.date_reservation)}</span>
                    </td>
                    <td>
                      <span className="text-muted">{item.ref}</span>
                    </td>
                    <td>
                      <strong>{formatPrices(item.total)}</strong>
                    </td>
                    <td>
                      <strong>{formatPrices(item.paye)}</strong>
                    </td>
                    <td>
                      <strong className="text-primary">
                        {formatPrices(item.solde)}
                      </strong>
                    </td>
                    <td>
                      <div>
                        <Link
                          to={"#"}
                          className={`btn btn-md btn-${getColorStatus(
                            item.status
                          )}`}
                        >
                          {item.status.toUpperCase()}
                        </Link>
                      </div>
                    </td>
                    <td>
                      <Actions
                        reservation={item}
                        setStatus={setStatus}
                        reservation_counts={reservation_counts}
                        setReservationCounts={setReservationCounts}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="9"
                    className="text-center"
                    style={{
                      fontSize: "1.5rem",
                    }}
                  >
                    Aucune réservation trouvée
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan="6" className="text-center  fs-20">
                  <Loading color="var(--secondary)" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {data.length > 0 && (
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
            <div className="dataTables_info">
              Affichage de{" "}
              {currentPage === 1 ? 1 : (currentPage - 1) * sort + 1} à{" "}
              {currentPage === 1
                ? sort
                : data.length === sort
                ? currentPage * sort
                : (currentPage - 1) * sort + data.length}{" "}
              sur {reservation_counts} entrées
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers mb-0"
              id="example2_paginate"
            >
              {currentPage === 1 ? (
                ""
              ) : (
                <>
                  <button
                    className="paginate_button previous disabled"
                    onClick={() => pagination(currentPage - 1)}
                  >
                    <i
                      className="fa fa-angle-double-left"
                      aria-hidden="true"
                    ></i>
                  </button>
                </>
              )}

              <span>
                <div className="paginate_button ">{currentPage}</div>
              </span>

              {currentPage >= Math.ceil(reservation_counts / sort) ? (
                ""
              ) : (
                <>
                  <button
                    className="paginate_button next"
                    onClick={() => pagination(currentPage + 1)}
                  >
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default List;
