import { useState, useEffect } from "react";

export function envoyer(facture_id) {
  console.log("envoi");
}

export function modifier(facture_id) {
  console.log("modification");
}

export function telecharger(facture_id) {
  console.log("telechargement");
}

export function useDisabled(inputFields) {
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    function toDisable() {
      const maxIdInputFields = inputFields.length - 1;
      if (
        inputFields?.length  > 0 &&
        inputFields[maxIdInputFields].raison !== "" &&
        inputFields[maxIdInputFields].prixTotal > 0
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
    toDisable();
  }, [inputFields]);

  return disabled;
}
