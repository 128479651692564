import React from "react";
import { Link } from "react-router-dom";

function Pagination({ 
  data, 
  sort, 
  nextChambres,
  prevChambres,
  end_point,
  start_point,
  chambres_counts,
  currentPage
 }) {
  return (
    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
      <div className="dataTables_info">
      Affichage de { 
        currentPage === 1 ? 1 : (currentPage - 1) * sort + 1
      } à { 
        currentPage === 1 ? sort : data.length === sort ? currentPage * sort : (currentPage - 1) * sort + data.length
      } sur {chambres_counts} entrées
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers mb-0"
        id="example2_paginate"
      >
        {
          currentPage === 1 ? '' : 
            (
              <>
                <button
                className="paginate_button previous disabled"
                onClick={() => prevChambres(start_point, end_point)}
                >
                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                </button>
            </>
          )
          }
          
          
          <span>
              <div
                className='paginate_button '
              >
                { currentPage }  
              </div>
          </span>
                
          {
            currentPage >= Math.ceil(chambres_counts / sort) ? '' :
          (
          <>
            <button
              className="paginate_button next"
              onClick= { ()=> nextChambres(start_point, end_point) }
            >
              <i className="fa fa-angle-double-right" aria-hidden="true"></i>
            </button>
          </>
          )
        }

      </div>
    </div>
  );
}

export default Pagination;
