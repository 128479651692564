import React from "react";
import { authSession, logoutSession } from "../services/SessionService";

const defaultContext = {
  state: {},
  dispatch: () => null,
};

const defaultState = {
  isAuthenticated: false,
};

const login = (state, action) => {
  authSession({
    log: action.id,
    password: action.password,
  }).then(({ data }) => {
    localStorage.setItem("clientDetails", JSON.stringify(data));
  });

  return {
    ...state,
    isAuthenticated: true,
  };
};

const logout = (action) => {
  logoutSession({
    log: action.id,
  }).then(() => {
    localStorage.removeItem("clientDetails");
  });

  return defaultState;
};

const checkLog = (state) => {
  const details = localStorage.getItem("clientDetails");
  const isAuthenticated = details ? true : false;

  return {
    ...state,
    isAuthenticated,
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "login":
      const newState = login(state, action);
      return newState;
    case "logout":
      return logout(action);
    case "checklog":
      return checkLog(state);
    default:
      throw new Error();
  }
};

const ClientContext = React.createContext(defaultContext);

const ClientProvider = ({ children, history }) => {
  const [state, dispatch] = React.useReducer(reducer, defaultState);

  React.useEffect(() => {
    if (state.isAuthenticated) {
      history.push("/session/");
    } else {
      history.push("/login");
    }
  }, [state]);

  React.useEffect(() => {
    dispatch({ type: "checklog" });
  }, []);

  return (
    <ClientContext.Provider value={{ state, dispatch }}>
      {children}
    </ClientContext.Provider>
  );
};

const useClient = () => React.useContext(ClientContext);

export { ClientContext, ClientProvider, useClient };
