import React, {useState} from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { validateForm } from "../../../../helpers/wifi";
import { updateWifiUser } from "../../../../services/wifiService";

const ToastOption = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  };


function ModifyWifiAccount ({handleClose, open, user, handleUserData})
{
    const [loading, setLoading] = useState(false);
    const notifyError = (message) => toast.error(message, ToastOption);
    const notifySuccess = (message) => toast.success(message, ToastOption);
  
    const save = async () => {
      
        if(validateForm(user)){
          setLoading(true)
          updateWifiUser(user)
            .then((response) => {
              if(response.status === 202 ){
                handleClose()
                toast.success(`Le compte utilisateur a été modifié`, ToastOption);
                setLoading(false)
              }
              else {
                toast.error(response.data.message, ToastOption)
              }
            })
            .catch((err) =>{
              notifyError(`Erreur pendant l'enregistrement `, ToastOption)
          })
          .finally( setLoading(false));
          
        } 
        else {
            notifyError("Veuillez remplir tous les champs");
            handleClose();
        }
      };  
    return (
    <Modal
      show={open}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modifier un compte utilisateur wifi
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="mb-4">Entrez les informations sur l'utilisateur'</h4>
        <div>
          <div className="form-group mb-3">
            <label>Nom*</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder={"Nom"}
              value={user.firstname}
              onChange={(e) => handleUserData("firstname", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Prénom*</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder={"Prénom"}
              value={user.lastname}
              onChange={(e) => handleUserData("lastname", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Contact</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder={"Contact"}
              value={user.contact}
              onChange={(e) => handleUserData("contact", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Localisation</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder={"Chambre 12"}
              value={user.localisation}
              onChange={(e) => handleUserData("localisation", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Addresse IP</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder={"192.168.88.11"}
              value={user.ip}
              onChange={(e) => handleUserData("ip", e.target.value)}
            />
          </div>

          <div className="form-group mb-3">
            <label>Date d'activation*</label>
            <input
              type="datetime-local"
              className="form-control input-default mb-2"
              placeholder={"Date d'activation"}
              value={user.date_activation}
              onChange={(e) => handleUserData("date_activation", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Date d'éxpiration*</label>
            <input
              type="datetime-local"
              className="form-control input-default mb-2"
              placeholder={"Date d'éxpration'"}
              value={user.date_expiration}
              onChange={(e) => handleUserData("date_expiration", e.target.value)}
            />
          </div>
          
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={handleClose}>
          Fermer
        </Button>
        <Button variant="primary" onClick={save} disabled={loading}>
          Modifier
        </Button>
      </Modal.Footer>
    </Modal>
    )
}

export default ModifyWifiAccount;