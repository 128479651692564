import Documentation from "../../pages/Documentation";
import GuidesPage from "../../pages/Documentation/GuidesPage";

import MainDocumentation from "./MainDocumentation";

export const documentationRoutes = [
  // Compte
  { url: "documentation", component: Documentation },

  // Compte
  { url: "documentation/compte", component: GuidesPage },
  { url: "documentation/compte/ajout", component: GuidesPage },
  { url: "documentation/compte/modifier", component: GuidesPage },

  // Rôle
  { url: "documentation/role", component: GuidesPage },
  { url: "documentation/role/ajout", component: GuidesPage },
  { url: "documentation/role/permission", component: GuidesPage },

  // Client
  { url: "documentation/client", component: MainDocumentation},
  { url: "documentation/client/ajout", component: MainDocumentation},
  { url: "documentation/client/modification", component: MainDocumentation},
  { url: "documentation/client/information", component: MainDocumentation },
  { url: "documentation/client/sessions", component: MainDocumentation },

  // chambre
  { url: "documentation/chambre", component: MainDocumentation},
  { url: "documentation/chambre/ajout", component: MainDocumentation},
  { url: "documentation/chambre/equipement", component: MainDocumentation},
  { url: "documentation/chambre/icones", component: MainDocumentation},
];
