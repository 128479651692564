import React from "react";

import "../../../scss/loading.scss"

function Loading({ color = "#fff" }) {
  const style = {
    background: color
  }
  return (
    <div className="d-flex w-100 justify-content-center">
      <div className="lds-facebook">
        <div style={style}></div>
        <div style={style}></div>
        <div style={style}></div>
      </div>
    </div>
  );
}

export default Loading;
