import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

function Tips() {
  let width = window.innerWidth;
  const placement = "Right";

  return (
    <OverlayTrigger
      trigger="hover"
      placement={
        width < 1300 && width > 700
          ? placement === "Left"
            ? "right"
            : placement.toLowerCase()
          : width < 700
          ? placement === "Right"
            ? "top"
            : width < 385
            ? placement === "Left"
              ? "bottom"
              : placement.toLowerCase()
            : placement.toLowerCase()
          : placement.toLowerCase()
      }
      responsive={true}
      overlay={
        <Tooltip
          className="toltip-popover"
          id={`popover-positioned-${placement.toLowerCase()}`}
        >
          <h3 className="popover-header">En savoir plus</h3>
          <strong>
            Savoir quoi mettre sur les champs icones
          </strong>
        </Tooltip>
      }
    >
      <Link
        className="text-dark ms-2"
        to={`#`}
        target="_blank"
      >
        <i className="fa fa-info-circle"></i>
      </Link>
    </OverlayTrigger>
  );
}

export default Tips;
