import React, { useState } from "react";
import { useParams } from "react-router-dom";

import RoomSlider from "../../components/Chambres/RoomSlider";
import {
  getChambreReservation,
  getChambresDetail,
  getChambresEquipment,
} from "../../../services/ChambreService";
import Loading from "../../components/Loading";
import { formatPrices } from "../../../helpers/numbers";
import { DetailActions } from "../../components/Chambres/Actions";
import AddEquipment from "../../components/Chambres/AddEquipment";
import Equipment from "../../components/Chambres/Equipment";
import Edit from "../../components/Chambres/Edit";
import OrderHistory from "../../components/Chambres/OrderHistory";

function DetailChambre() {
  const [data, setData] = useState(null);
  const [orders, setOrders] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingEquipment, setLoadingEquipment] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const params = useParams();
  const id = params.id;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const handleEquipment = () => {
    setLoadingEquipment(true);
    getChambresEquipment(id)
      .then(({ data }) => setEquipments(data))
      .finally(() => setLoadingEquipment(false));
  };

  const handleOrders = () => {
    getChambreReservation(id)
      .then(({ data }) => {
        if (data && data.reservations) {
          setOrders(data.reservations);
        }
      })
      .finally(() => setLoadingOrders(false));
  }

  const handleData = () =>
    getChambresDetail(id)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => setLoading(false));

  React.useEffect(() => {
    handleData();
    handleEquipment();
    handleOrders();
  }, [id]);

  return (
    <>
      <div className="row mt-4">
        <div className="col-xl-12">
          {loading ? (
            <Loading color="var(--secondary)" />
          ) : !data ? (
            <>Erreur</>
          ) : (
            <div className="row">
              <AddEquipment
                handleClose={handleClose}
                id_chambre={data.id}
                open={open}
                handleEquipment={handleEquipment}
              />
              <Edit
                open={openEdit}
                chambre={data}
                handleClose={handleCloseEdit}
                handleData={handleData}
              />
              <div className="col-xl-12">
                <div className="card overflow-hidden">
                  <div className="row m-0">
                    <div className="col-xl-6 p-0">
                      <div className="card-body">
                        <div className="d-flex flex-wrap">
                          <div className="mt-4 check-status">
                            <span className="d-block mb-2">Informations</span>
                            <h4 className="font-w500 fs-24">{data.name}</h4>
                          </div>
                          <div className="mt-4 ms-3">
                            <span className="d-block mb-2 text-black">
                              Prix{" "}
                              <span className="text-primary">
                                (règles inclus)
                              </span>
                            </span>
                            <span className="font-w500 fs-24 text-black">
                              {formatPrices(data.price)} Ar
                              <small className="fs-14 ms-2 text-secondary">
                                /nuit
                              </small>
                            </span>
                          </div>
                          <DetailActions
                            handleOpen={handleOpen}
                            handleOpenEdit={handleOpenEdit}
                          />
                        </div>
                        <p className="mt-2">{data.description??""}</p>
                        <Equipment
                          equipments={equipments}
                          loading={loadingEquipment}
                          handleEquipment={handleEquipment}
                          id_chambre={id}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 p-0">
                      <RoomSlider chambre={data} equipements={equipments} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <OrderHistory datas={orders} loading={loadingOrders} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DetailChambre;
