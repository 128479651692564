import { twoDigit } from "./numbers";

const months_S = [
  "Jan",
  "Fev",
  "Mars",
  "Avr",
  "Mai",
  "Juin",
  "Jul",
  "Aout",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function formatDate(date) {
  const d = new Date(date);
  return `${twoDigit(d.getDate())} ${months_S[d.getMonth()]} ${d
    .getFullYear()
    .toString()
    .substr(-2)}`;
}

export function formatFullDate(date) {
  const d = new Date(date);
  return `${twoDigit(d.getDate())} ${
    months_S[d.getMonth()]
  } ${d.getFullYear()}`;
}

export function formatDateTime(date) {
  const d = new Date(date);
  return `${twoDigit(d.getDate())} ${
    months_S[d.getMonth()]
  } ${d.getFullYear()} ${twoDigit(d.getHours())}h${twoDigit(d.getMinutes())}`;
}

export function formatTime(date) {
  const d = new Date(date);
  return `${twoDigit(d.getHours())}h${twoDigit(d.getMinutes())}`;
}

export function compare(d1, d2) {
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  return date1.getTime() > date2.getTime();
}

export function dateStringToDateTime(date) {
  const d = new Date(date);
  return d.toISOString().slice(0, 19).replace("T", " ");
}

export function formatInputDateTime(date) {
  const d = new Date(date);
  return `${d.getFullYear()}-${twoDigit(d.getMonth() + 1)}-${twoDigit(
    d.getDate()
  )}T${twoDigit(d.getHours())}:${twoDigit(d.getMinutes())}`;
}

export function formatInputDate(date) {
  const d = new Date(date);
  return `${d.getFullYear()}-${twoDigit(d.getMonth() + 1)}-${twoDigit(
    d.getDate()
  )}`;
}
