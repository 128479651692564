import * as Yup from "yup";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { useDisabled } from "./action";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Row, Col } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import logo from "../../../images/logo.png"; 
// import service
import {
  getAllCheckoutsNeedInvoices,
  getClient,
  postFacture,
} from "../../../services/Facture";

// import helpers
import { dateStringToDateTime } from "../../../helpers/dates";
import CheckoutItem from "./Checkout_item";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Details() {
  const id_client = parseInt(useParams().id);

  const ToastOption = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  };

  const [inputFields, setInputFields] = useState([]);
  const history = useHistory()
  const [totalDu, setTotalDu] = useState(0);
  const [client, setClient] = useState([]);
  const [responsable, setResponsable] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [checkouts, setCheckouts] = useState([]);
  const [checkoutsById, setCheckoutsById] = useState([]);
  const [checkoutSelected, setCheckoutSelected] = useState([]);

  const { displayName, id } = JSON.parse(localStorage.getItem("userDetails"));

  // Quand le total change c'est-à-dire quand on ajoute un autre achat
  useEffect(() => {
    let total = 0;
    inputFields.forEach((item) => {
      total += parseInt(item.prixTotal);
    });
    setTotalDu(total);
  }, [inputFields]);

  // On récupère les informations du client quand le composant est monté
  useEffect(() => {
    getClient().then((res) => {
      setClient(res.data.info_facture);
    });
  }, []);

  // On récupère les informations du responsable quand le composant est monté
  useEffect(() => {
    getClient().then((res) => {
      setResponsable(res.data.responsables);
    });
  }, []);

  const validationSchema = Yup.object().shape({
    date_publication: Yup.date().required("Date de publication requis"),
    date_order: Yup.date().required("Date d'écheance requis"),
    client: Yup.string().notRequired(),
    iban: Yup.string().notRequired(),
    responsable: Yup.string().required("Responsable requis"),
    banque: Yup.string().notRequired(),
    pays: Yup.string().notRequired(),
  });

  const save = (e) => {
    setIsLoading(true);

    const dataFinal = {
      date_publication: dateStringToDateTime(e.date_publication),
      date_echeance: dateStringToDateTime(e.date_order),
      responsable_id: parseInt(e.responsable),
      iban: e.iban,
      pays: e.pays,
      banque: e.banque,
      client_id: e.client,
      checkouts: JSON.stringify(checkoutSelected),
    };

    console.log(dataFinal)

    postFacture(dataFinal)
      .then((res) => {
        setIsLoading(false);
        swal({
          title: "Succès",
          text: "Facture ajoutée avec succès!! Vous voulez le voir?",
          icon: "success",
          buttons: {
            confirm: "Oui",
            cancel: "Non",
          },
        }).then(() => {
          history.push(`/invoices/generate/${res.data.id}`);
        });
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(`Une erreur s'est produite : ${err.message}`, ToastOption);
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  /***** */

  useEffect(() => {
    getAllCheckoutsNeedInvoices().then((res) => {
      setCheckouts(res.data?.data);
    });
  }, []);

  useEffect(() => {
    const checkoutsResult = checkouts.filter(
      (checkout) => checkout.id == selectedClient
    );
    if (checkoutsResult?.length > 0) {
      setCheckoutsById(checkoutsResult[0]?.checkouts?.checkouts);
    }
  }, [selectedClient]);

  useEffect(() => {
    const newInputValue = [];
    checkoutSelected?.forEach((index) => {
      const result = checkoutsById?.find((checkout) => checkout.id == index);
      if (result) {
        const resultObject = {
          raison: result.raison,
          prixTotal: result.prixTotal,
          date: result.updated_at,
        };
        newInputValue.push(resultObject);
      }
    });

    setInputFields(newInputValue);
  }, [checkoutSelected]);

  return (
    <Form onSubmit={handleSubmit(save)}>
      <div>
        <Card>
          <Card.Body>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div style={{ width: "50%" }}>
              <div
                style={{
                  display: "flex",
                }}
              >
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    marginRight: "15px",
                    marginTop: "15px",
                  }}
                  src={logo}
                />
                <p
                  style={{
                    marginTop: "15px",
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                    textAlign: "center",
                  }}
                >
                  Stephan <br />
                  Hotel
                </p>
              </div>
              <div className="d-flex flex-column">
                <span>129, Bd JOFFRE, TAMATAVE</span>
                <span>O387883477 / O328337743</span>
                <span>NIF: 400348443</span>
                <span>STAT:56101312009000462</span>
              </div>
            </div>
              <div
                style={{
                  width: "20%",
                }}
              >
                <div className="form-group mb-4">
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      textAlign: "right",
                    }}
                  >
                    {" "}
                    Facture: #..........{" "}
                  </p>

                  <Form.Label
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Date de publication{" "}
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="date_publication"
                    className="form-control"
                    defaultValue={new Date().toISOString().substr(0, 10)}
                    required={errors.date_publication ? true : false}
                    {...register("date_publication")}
                  />
                </div>
                <div className="form-group mb-4">
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Date d'écheance
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="date_order"
                    className="form-control"
                    required={errors.date_order ? true : false}
                    {...register("date_order")}
                  />
                </div>
              </div>
            </div>
            <hr></hr>
            <div
              style={{
                display: "flex",
                marginTop: "50px",
                marginBottom: "40px",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                  Facturer pour:
                </p>
                <p style={{ fontSize: "20px" }}>Client: </p>
                <Form.Select
                  style={{
                    width: "300px",
                    height: "50px",
                  }}
                  name="client"
                  {...register("client")}
                  required={errors.client ? true : false}
                  onChange={(e) => {
                    let selected = e.target.value;
                    setSelectedClient(selected);
                  }}
                >
                  {" "}
                  {client.length &&
                    (id_client ? (
                      <option key={id_client} value={id_client}>
                        {" "}
                        {
                          client.filter(
                            (item) => item.id === parseInt(id_client)
                          )[0].nom
                        }
                      </option>
                    ) : (
                      <>
                        <option value="">--Selectionner un client--</option>
                        {client.length ? (
                          client.map((item) => (
                            <option value={item.id}>{item.nom}</option>
                          ))
                        ) : (
                          <option value="">--Aucun client trouvé --</option>
                        )}
                      </>
                    ))}
                </Form.Select>
              </div>
              <div>
                <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                  Facturer:{" "}
                </p>
                <p style={{ fontSize: "20px" }}>Code rapide: #........ </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                    }}
                  >
                    <Form.Label
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Total dû
                    </Form.Label>
                    <Form.Control type="text" id="text1" value={totalDu} />
                    <Form.Label
                      style={{
                        marginTop: "30px",
                        fontWeight: "bold",
                      }}
                    >
                      Pays
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="pays"
                      {...register("pays")}
                    />
                  </div>
                  <div>
                    <Form.Label
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Banque
                    </Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue="SG"
                      name="banque"
                      {...register("banque")}
                    />
                    <Form.Label
                      style={{
                        marginTop: "30px",
                        fontWeight: "bold",
                      }}
                    >
                      IBAN
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="iban"
                      required={errors.iban ? true : false}
                      {...register("iban")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>

            {selectedClient !== "" && (
              <>
                <Row
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <Col sm={5}>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      Tous les checkouts
                    </p>

                    {checkoutsById?.map((checkout, index) => {
                      const checked = checkoutSelected?.some(
                        (item) => item.id == checkout.id
                      );
                      return (
                        <CheckoutItem
                          checkout={checkout}
                          checked={checked}
                          key={index}
                          setCheckoutSelected={setCheckoutSelected}
                        />
                      );
                    })}
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                        Eléments
                      </p>
                    </div>
                    <div className="table-responsive">
                      <div
                        id="example2_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          id="example2"
                          className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
                        >
                          <thead>
                            <tr role="row">
                              <th className="sorting_asc">Raison</th>
                              <th className="sorting">Prix Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {inputFields?.map((inputField, index) => (
                              <tr
                                role="row"
                                className="odd"
                                style={{
                                  fontSize: "30px",
                                }}
                              >
                                <td className="sorting_1">
                                  {inputField.raison}
                                </td>
                                <td>{inputField.prixTotal}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "100px",
                marginBottom: "100px",
              }}
            >
              <div>
                <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                  Responsable:
                </p>

                <Form.Select
                  style={{
                    width: "300px",
                    height: "50px",
                  }}
                  name="responsable"
                  {...register("responsable")}
                  required={errors.responsable ? true : false}
                >
                  <option value={id}> {displayName}</option>
                  {responsable
                    .filter((resp) => resp.id !== id)
                    .map((resp, index) => (
                      <option key={index} value={resp.id}>
                        {resp.name}{" "}
                      </option>
                    ))}
                </Form.Select>
              </div>{" "}
              <div
                style={{
                  fontSize: "20px",
                }}
              >
                <p>Sous Total : {totalDu}</p>
                <p>Remise: 0</p>
                <p>Taxes: 0 </p>
                <p>Total: {totalDu}</p>
              </div>{" "}
            </div>

            <Button
              variant="success"
              style={{
                width: "100%",
                backgroundColor: "#116455",
              }}
              type="submit"
              disabled={isLoading}
            >
              Enregistrer
            </Button>
          </Card.Body>
        </Card>
      </div>
    </Form>
  );
}

export default Details;
