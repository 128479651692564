import React from "react";
import { Button, Card } from "react-bootstrap";
import { getSessions, removeSession } from "../../../services/SessionService";
import Loading from "../Loading";
import { compare, formatDate, formatTime } from "../../../helpers/dates";
import AddSession from "./AddSession";
import { getHistoriqueWifi } from "../../../services/wifiService";

const SessionItem = ({ historique }) => {
  return (
    <tr>
      <td>{historique.login}</td>
      <td>
        <small className="d-block text-muted">Réference Commande</small>
        <strong className="d-block">{historique.commandeRef}</strong>
      </td>
      <td>
        <span
          className={`btn btn-md btn-${
            historique.status == "Disponible" ? "secondary" : "primary"
          }`}
        >
          {historique.status}
        </span>
      </td>
    </tr>
  );
};

function HistoriqueWifi({ data, loadignHisto }) {
  return (
    <Card className="w-100">
      <Card.Body>
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <h4 className="mb-0">Historique de connexion Wifi</h4>
        </div>
        <table className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer">
          <tbody>
            {data.map((item, index) => (
              <SessionItem historique={item} key={index} />
            ))}
          </tbody>
        </table>
        {loadignHisto ? <Loading color="var(--primary)" /> : <></>}
      </Card.Body>
    </Card>
  );
}

export default HistoriqueWifi;
