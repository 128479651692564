import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CheckoutGenerate from "./Checkout_generate";
import { showCheckout } from "../../../services/CheckoutService";


function ShowCheckout () {
    const { id } = useParams();
    const [data, setData] = useState([]);
    useEffect(() => {
        showCheckout(id).then((response) => {
            setData(response.data);
        });
    }, []);

    console.log(data)

    return (
        <CheckoutGenerate data={data} />
    )
}

export default ShowCheckout;