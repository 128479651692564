import section1 from  '../../../images/documentation/clients/information_section_1.png';
import section2 from  '../../../images/documentation/clients/information_section_2.png';
import section3 from  '../../../images/documentation/clients/information_section_3.png';



export const ClientInformationGuide = [
    {
        title: "Cliquer sur le bouton 'Clients' dans le menu de navigation.",
        paragraph: "Le bouton 'Clients' se trouve dans le menu de navigation à gauche de l'écran.",
        illustration : null,
    },
    {
        title: "Cliquer sur le client dont vous voulez voir les informations.",
        paragraph: "Le client se trouve dans la liste des clients.",
        illustration : null,
    },
    {
        title: "Section 1: on a les informations detaillées du client s'affichent.",
        paragraph: "Vous pouvez voir les informations du client.",
        illustration : section1,
    },
    {
        title : null,
        paragraph : null,
        illustration : null,
    },
    {
        title: "Section 2: on a la liste des réservations du client s'affichent.",
        paragraph: ` Vous pouvez voir la chambre réservée, la date de début d'arrivée et départ du client et le prix de la réservation.
                     On peut savoir plus sur cette réservatin en appuyant sur le bouton 'Voir' à droite de la ligne de la réservation.
        `,
        illustration : section2,
    }
    ,
    {
        title: "Section 3: on y voit les identifiants de connexion du client et ses appels.",
        paragraph: `Vous pouvez en savoir plus sur `+process.env.REACT_APP_PEER_HOST+`/documentation/client/sessions`,
        illustration : section3,
    }
]