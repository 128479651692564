import { createFormData } from "../helpers/services";
import axiosInstance from "./AxiosInstance";

export function getUsers() {
  return axiosInstance.get(`user/browse`);
}

export function createUser(postData) {
  return axiosInstance.post(`user/create`, createFormData(postData));
}

export function editUser(user, postData) {
  return axiosInstance.post(`user/edit/${user.id}`, createFormData(postData));
}

export function getRoles() {
  return axiosInstance.get(`role/browse`);
}

export function getPermissions() {
  return axiosInstance.get(`permissions/browse`);
}

export function createRole(role) {
  return axiosInstance.post(`role/create`, createFormData({ role }));
}

export function removeRole(role) {
  return axiosInstance.post(`role/delete/${role.id}`);
}

export function assignPermission({ role, permission }) {
  return axiosInstance.post(`role/assignPermission`, createFormData({ role, permission }));
}

export function retirePermission({ role, permission }) {
  return axiosInstance.post(`role/retirePermission`, createFormData({ role, permission }));
}