const requiredForm = ["firstname", "lastname","date_activation", "date_expiration"];

export function validateForm(inputs) {
    for (const key in inputs) {
        if (Object.hasOwnProperty.call(inputs, key)) {
        const val = inputs[key];
        if (
            requiredForm.indexOf(key) > -1 &&
            (val === "" || val === null || val === undefined)
        )
            return false;
        }
    }
    return true;
}