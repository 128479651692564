import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.auth.idToken;

  config.headers = config.headers || {};
  config.headers["Authorization"] = `Bearer ${token}`;
  config.headers["Content-Type"] = `multipart/form-data`;
  return config;
});

export const axiosMikrotiInstance = axios.create({
  baseURL: process.env.REACT_APP_MIKROTIK_URL,
  auth: {
    username: process.env.REACT_APP_MIKROTIK_USERNAME,
    password: process.env.REACT_APP_MIKROTIK_PASSWORD,
  },
});

export default axiosInstance;
