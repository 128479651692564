import { createFormData } from "../helpers/services";
import axiosInstance from "./AxiosInstance";

export function getClients() {
  return axiosInstance.get(`client/browse`);
}
export function getClientsAll() {
  return axiosInstance.get(`client/all`);
}

export function getClientsCount() {
  return axiosInstance.get(`client/browse/count`);
}

export function getClient(id) {
  return axiosInstance.get(`client/show/${id}`);
}

export function getClientReservation(id) {
  return axiosInstance.get(`reservation/client/${id}`);
}

export function createClient(data) {
  return axiosInstance.post(`client/create`, createFormData(data));
}

export function editClient(id, data) {
  return axiosInstance.post(`client/edit/${id}`, createFormData(data));
}

export function onlineClients() {
  return axiosInstance.get(`client/connected`);
}

export function archiveClient(id) {
  return axiosInstance.patch(`client/archive/${id}`);
}

export function generateWifiUser(id) {
  return axiosInstance.post(`client-wifi/create-user/${id}`);
}

export function getWifiUser(id) {
  return axiosInstance.get(`client-wifi/get-user`, {
    params: { client: id },
  });
}
