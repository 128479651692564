const requiredForm = ["name", "price", "bed"];
const requiredEquipmentForm = ["name", "title"];

export function validateForm(inputs) {
  for (const key in inputs) {
    if (Object.hasOwnProperty.call(inputs, key)) {
      const val = inputs[key];
      if (
        requiredForm.indexOf(key) > -1 &&
        (val === "" || val === null || val === undefined)
      )
        return false;
    }
  }
  return true;
}

export function validateEquipmentForm(inputs) {
  for (const key in inputs) {
    if (Object.hasOwnProperty.call(inputs, key)) {
      const val = inputs[key];
      if (
        requiredEquipmentForm.indexOf(key) > -1 &&
        (val === "" || val === null || val === undefined)
      )
        return false;
    }
  }
  return true;
}
