import section3 from  '../../../images/documentation/clients/information_section_3.png';
import session1 from  '../../../images/documentation/clients/session_client1.png';

export const ClientSesssionGuide = [
    {
        title: "Etape 1 : Cliquer sur le bouton 'Clients' dans le menu de navigation.",
        paragraph: "Le bouton 'Clients' se trouve dans le menu de navigation à gauche de l'écran.",
    },
    {
        title: "Etape 2 : Cliquer sur le client dont vous voir les sessions.",
        paragraph: "Vous pouvez utiliser la barre de recherche pour trouver un client en particulier.",
        illustration : section3,
    },
    {
        title: "Etape 3 : A la dernièrne section, on y voit les identifiants de connexion du client et ses appels.",
        paragraph: "Vous pouvez voir toutes les sessions du client. S'il n'y a pas de session, vous pouvez en ajouter une en cliquant sur le bouton 'Ajouter une nouvelle session'.",
    },
    {
        title: "Etape 4 : Cliquer sur le bouton 'Ajouter une nouvelle session'.",
        paragraph:`et vous verez les formulaires pour ajouter une nouvelle session, puis choisir la réservation
                  NB : apres avoir choisi, les informations fournies seront pas réaffichées, donc vous devez les enregsitrer.`,
        illustration: session1,
    },
    {
        title: "Etape 5 : connecter le client avec les informations fournies.",
        paragraph: " Rendez-vous sur la page de connexion de session client"+ process.env.REACT_APP_PEER_HOST +"/session/login, puis connectez-vous avec les informations fournies.",
    }

]