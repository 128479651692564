/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

import User from "./User";
import CheckPermission from "../../../templates/components/CheckPermission/CheckPermission";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  //console.log(sidebarposition);
  //console.log(sidebarLayout);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast);
  }, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  /// Path
  let path = window.location.pathname.split('/')[1]

  /// Active menu
  let dashBoard = ["", "dashboard"],
    clients = ["clients", 'client'],
    chambres = ["rooms", 'room'],
    calendrier = ["calendar"],
    reservations = ["orders", "order"],
    factures = ["invoices", "invoice"],
    checkout = ["checkout"],
    appels = ["call"],
    clients_actifs = ["users", "user"],
    comptes = ["accounts", "account"],
    wifi = ["wifi"];

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
            <Link to="/" className="ai-icon">
              <i className="fas fa-chart-line"></i>
              <span className="nav-text">Tableau de bord</span>
            </Link>
          </li>
          <CheckPermission permission="browse_client">
            <li className={`${clients.includes(path) ? "mm-active" : ""}`}>
              <Link to="/clients" className="ai-icon">
                <i className="fas fa-users"></i>
                <span className="nav-text">Clients</span>
              </Link>
            </li>
          </CheckPermission>
          <CheckPermission permission="browse_room">
            <li className={`${chambres.includes(path) ? "mm-active" : ""}`}>
              <Link to="/rooms" className="ai-icon">
                <i className="fas fa-door-open"></i>
                <span className="nav-text">Chambres</span>
              </Link>
            </li>
          </CheckPermission>
          <li className={`${calendrier.includes(path) ? "mm-active" : ""}`}>
            <Link to="/calendar" className="ai-icon">
              <i className="fas fa-calendar"></i>
              <span className="nav-text">Calendrier</span>
            </Link>
          </li>
          <CheckPermission permission="browse_reservation">
          <li className={`${reservations.includes(path) ? "mm-active" : ""}`}>
            <Link to="/orders" className="ai-icon">
              <i className="fas fa-history"></i>
              <span className="nav-text">Reservations</span>
            </Link>
          </li>
          </CheckPermission>
          <CheckPermission permission="browse_facture">
          <li className={`${factures.includes(path) ? "mm-active" : ""}`}>
            <Link to="/invoices" className="ai-icon">
              <i className="fas fa-receipt"></i>
              <span className="nav-text">Factures</span>
            </Link>
          </li>
          </CheckPermission>
          <CheckPermission permission="browse_checkout">
          <li className={`${checkout.includes(path) ? "mm-active" : ""}`}>
            <Link to="/checkout" className="ai-icon">
              <i className="fas fa-credit-card"></i>
              <span className="nav-text">Checkout</span>
            </Link>
          </li>
          </CheckPermission>
          <li className={`${appels.includes(path) ? "mm-active" : ""}`}>
            <Link to="/call" className="ai-icon">
              <i className="fas fa-phone"></i>
              <span className="nav-text">Appels</span>
            </Link>
          </li>
          <CheckPermission permission="browse_wifi">
          <li className={`${wifi.includes(path) ? "mm-active" : ""}`}>
            <Link to="/wifi" className="ai-icon">
              <i className="fas fa-wifi"></i>
              <span className="nav-text">Wifi</span>
            </Link>
          </li>
          </CheckPermission>
          <li className={`${clients_actifs.includes(path) ? "mm-active" : ""}`}>
            <Link to="/client/online" className="ai-icon">
              <i className="fas fa-user-circle"></i>
              <span className="nav-text">Clients connectés</span>
            </Link>
          </li>
          <CheckPermission permission="browse_user">
            <li className={`${comptes.includes(path) ? "mm-active" : ""}`}>
              <Link to="/accounts" className="ai-icon">
                <i className="fas fa-user-cog"></i>
                <span className="nav-text">Comptes</span>
              </Link>
            </li>
          </CheckPermission>
          {/* <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#" >
              <i className="fas fa-home"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
            <ul >
              <li><Link className={`${path === "dashboard" ? "mm-active" : ""}`} to="/dashboard"> Dashboard Light</Link></li>
              <li><Link className={`${path === "dashboard-dark" ? "mm-active" : ""}`} to="/dashboard-dark"> Dashboard Dark</Link></li>
              <li><Link className={`${path === "guest-list" ? "mm-active" : ""}`} to="/guest-list">Guest List</Link></li>
              <li><Link className={`${path === "guest-details" ? "mm-active" : ""}`} to="/guest-details">Guest Details</Link></li>
              <li><Link className={`${path === "concierge-list" ? "mm-active" : ""}`} to="/concierge-list">Concierge List</Link></li>
              <li><Link className={`${path === "room-list" ? "mm-active" : ""}`} to="/room-list">Room List</Link></li>
              <li><Link className={`${path === "reviews" ? "mm-active" : ""}`} to="/reviews">Reviews</Link></li>
              <li><Link className={`${path === "task" ? "mm-active" : ""}`} to="/task">Task</Link></li>
            </ul>
          </li> */}
        </MM>
        <User />
        <div className="copyright">
          <p className="text-center">
            <strong>Stephen Hotel Admin Dashboard</strong> © 2023 All Rights
            Reserved
          </p>
          <p className="fs-12 text-center">
            Made with <span className="heart"></span> by LCom
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
