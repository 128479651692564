import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { validationCheckoutSchema } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useDisabled } from "./action";
import { getClientsAll } from "../../../services/ClientService";
import { createCheckout } from "../../../services/CheckoutService";
import swal from "sweetalert";
import { toast } from "react-toastify";


function Add2Checkout() {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [inputFields, setInputFields] = useState([
    { raison: "", prixTotal: 0 },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0)
  const disabled = useDisabled(inputFields);

  const ToastOption = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ raison: "", prixTotal: 0 });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "raison") {
      values[index].raison = event.target.value;
    } else if (event.target.name === "prixTotal") {
      values[index].prixTotal = event.target.value;
    }
    setInputFields(values);
  };

  const save = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const data = {
      paniers : JSON.stringify(inputFields),
      client_id : selectedClient
    }
    
    createCheckout(data).then(res => {
      setIsLoading(false);
        // swal({
        //   title: "Succès",
        //   text: "Checkout ajoutée avec succès!! Vous voulez le voir?",
        //   icon: "success",
        //   buttons: {
        //     confirm: "Oui",
        //     cancel: "Non",
        //   },
        // }).then(() => {
        //   //window.location.href = `invoices/generate/${res.data.id}`;
        //   console.log(res.data)
        // });
        setIsLoading(false);
        toast.success(`Checkout ajoutée avec succès!!`, ToastOption);
        setInputFields([
          { raison: "", prixTotal: 0 },
        ])
        setTotal(0)
        setSelectedClient("")
    }).catch(err => {
      setIsLoading(false);
        toast.error(`Une erreur s'est produite : ${err.message}`, ToastOption);
    })
  }

  useEffect(() => {
    // verif if inputField is empty
    if (inputFields?.length == 0) {
      setInputFields([
        { raison: "", prixTotal: 0},
      ])
    }
  }, [inputFields])

  useEffect(() => {
    let total = 0;
    inputFields.forEach((item) => {
      total += parseInt(item.prixTotal);
    });

    setTotal(total);
  }, [inputFields]);

  useEffect(() => {
      getClientsAll().then((res) => {
        setClients(res.data);
      });
    }, []);

  

  return (
    <div className="d-flex">
      <Card
        style={{
          width: "80%",
          marginRight: "3rem",
        }}
      >
        {" "}
        <Form onSubmit={(e) => save(e)}>
          <Card.Body>
            <div className="mb-5">
              <h1 className="mb-3">Checkout pour</h1>
              <Col>
                <Form.Label className="fw-bold fs-5">Client</Form.Label>
                <Form.Select
                  style={{
                    width: "300px",
                    height: "50px",
                  }}
                  name="client_id"
                  required
                  onChange={(e) => {
                    let selected = e.target.value;
                    setSelectedClient(selected);
                  }}
                >
                  {" "}
                  {clients?.length && (
                    <>
                      <option value="">--Selectionner un client--</option>
                      {clients?.length ? (
                        clients?.map((item) => (
                          <option value={item.id}>{item.firstname} {item.lastname}</option>
                        ))
                      ) : (
                        <option value="">--Aucun client trouvé --</option>
                      )}
                    </>
                  )}
                </Form.Select>
              </Col>
            </div>
            <hr></hr>
            <p style={{ fontWeight: "bold", fontSize: "20px" }}>Eléments</p>

              {inputFields?.map((inputField, index) => {
                return (
                  <Row
                    key={index}
                    style={{
                    }}
                  >
                    <Col sm={7}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Raison
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Petit déjeuner"
                        name="raison"
                        required
                        defaultValue={inputField.raison}
                        onChange={(event) => {
                          handleChange(index, event);
                        }}
                      />
                    </Col>

                    <Col sm={3}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Prix total
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="prixTotal"
                        defaultValue={inputField.prixTotal}
                        onChange={(event) => {
                          handleChange(index, event);
                        }}
                      />
                    </Col>
                    <Col
                      style={{
                        marginTop: "30px",
                      }}
                    >
                      <Button
                        className=""
                        variant="danger"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(index)}
                      >
                        <i class="fa fa-minus" aria-hidden="true"></i>
                      </Button>
                    </Col>
                  </Row>
                );
              })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginRight: "20px",
                  marginTop: "20px"
                }}
              >
                <p style={{ fontWeight: "bold", fontSize: "20px" }}></p>
                <Button
                  className="ms-3"
                  variant="secondary"
                  onClick={handleAddFields}
                  disabled={disabled}
                >
                  <i
                    className="fa fa-plus"
                    style={{
                      marginRight: "10px",
                    }}
                  ></i>
                  Ajouter un autre checkout
                </Button>
              </div>
              <Button
              variant="success"
              style={{
                width: "100%",
                backgroundColor: "#116455",
                marginTop : "20px"
              }}
              type="submit"
              disabled={isLoading}
            >
              Enregistrer
            </Button>
          </Card.Body>
        </Form>
      </Card>
      <Card
        style={{
          width: "30%",
        }}
      >
        <Card.Body>
            <h1 className="mt-2 mb-5">Votre Panier</h1>
          {inputFields?.length > 0 ? (
            inputFields?.map((item) => (
              <>
                <div className="d-flex justify-content-between">
                  <p className="fs-20">{item.raison}</p>
                  <p className="fs-20">{item.prixTotal} Ar</p>
                </div>
                <hr></hr>{" "}
              </>
            ))
          ) : (
            <p className="fs-20">Panier vide</p>
          )}
          <Button className="w-100 ">
            <div className="d-flex justify-content-between">
              <p className="fs-20 fw-bold">Total</p>
              <p className="fs-20 fw-bold"> {total} Ar</p>
            </div>
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Add2Checkout;
