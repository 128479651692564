import React from "react";
import swal from "sweetalert";

function DeleteAccount({ user, handleUsers }) {
  const handleDelete = () => {
    swal({
      title: "Êtes-vous sûr?",
      text: "Une fois supprimé, vous ne pourrez plus récupérer ce compte!",
      icon: "warning",
      buttons: {
        cancel: "Annuler",
        confirm: "Supprimer",
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("La fonctionnalité ne marche pas encore!", {
          icon: "warning",
        });
        handleUsers()
      }
    });
  };

  return (
    <span className="btn btn-danger shadow btn-xs sharp" onClick={handleDelete}>
      <i className="fa fa-trash"></i>
    </span>
  );
}

export default DeleteAccount;
