export const DictionnairePermission = {
    create_role: "Créer un rôle",
    browse_role: "Consulter les rôles",
    edit_role: "Modifier un rôle",
    delete_role: "Supprimer un rôle",
    create_user: "Créer un utilisateur",
    browse_user: "Consulter les utilisateurs",
    edit_user: "Modifier un utilisateur",
    delete_user: "Supprimer un utilisateur",
    create_room: "Créer une chambre",
    browse_room: "Consulter les chambres",
    edit_room: "Modifier une chambre",
    delete_room: "Supprimer une chambre",
    create_client: "Créer un client",
    browse_client: "Consulter les clients",
    edit_client: "Modifier un client",
    delete_client: "Supprimer un client",
    create_facture: "Créer une facture",
    browse_facture: "Consulter les factures",
    create_reservation: "Créer une réservation",
    browse_reservation: "Consulter les réservations",
    edit_reservation: "Modifier une réservation",
    delete_reservation: "Supprimer une réservation",
    create_checkout: "Créer un paiement",
    browse_checkout: "Consulter les paiements",
    create_wifi : "Créer un identifiant wifi",
    browse_wifi : "Consulter la section wifi",
    delete_user_wifi : "Supprimer un identifiant wifi",
};