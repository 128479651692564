import React from "react";
import SessionLayout from "./layout";
import { Nav, Tab } from "react-bootstrap";
import Calls from "./calls";
import History from "./history";
import Paused from "./paused";

function Home() {
  return (
    <SessionLayout title="Appels">
      <Tab.Container defaultActiveKey={`calls`}>
        <Nav as="ul" className="nav-pills mb-4 light">
          <Nav.Item as="li">
            <Nav.Link eventKey={`calls`} style={{ cursor: "pointer" }}>
              <i className="fa fa-user me-2"></i>
              <span>Contacts</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link eventKey={`history`} style={{ cursor: "pointer" }}>
              <i className="fa fa-history me-2"></i>
              <span>Historique</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link eventKey={`pause`} style={{ cursor: "pointer" }}>
              <i className="far fa-pause-circle me-2"></i>
              <span>En attente</span>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="">
          <Tab.Pane eventKey={`calls`}>
            <Calls />
          </Tab.Pane>
          <Tab.Pane eventKey={`history`}>
            <History />
          </Tab.Pane>
          <Tab.Pane eventKey={`pause`}>
            <Paused />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </SessionLayout>
  );
}

export default Home;
