import { createFormData } from "../helpers/services";
import axiosInstance from "./AxiosInstance";

export function getSessions(id) {
  return axiosInstance.get(`session/client/${id}`);
}

export function createSession(postData) {
  return axiosInstance.post(`session/create`, createFormData(postData));
}

export function refreshSession(id) {
  return axiosInstance.post(`session/edit/${id}`);
}

export function removeSession(id) {
  return axiosInstance.post(`session/delete/${id}`);
}

export function authSession(postData) {
  return axiosInstance.post(`session/auth`, createFormData(postData));
}

export function logoutSession(postData) {
  return axiosInstance.post(`session/logout`, createFormData(postData));
}

export function getContacts() {
  return axiosInstance.get(`session/contacts`);
}