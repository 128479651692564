import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";

function Contact({ contact, callUser, show }) {
  const [loading, setLoading] = useState(false);

  const calling = () => {
    callUser(contact);
    setLoading(true);
  };

  useEffect(() => {
    if (show) {
      setLoading(false);
    }
  }, [show]);

  return (
    <Card>
      <div className="d-flex w-100 p-3">
        <img
          src="https://img.freepik.com/free-icon/user_318-159711.jpg"
          className="me-3 rounded"
          alt=""
          height={60}
          width={60}
        />
        <div className="w-100">
          <h5>{contact.name}</h5>
          <span className="d-block mb-3">Receptionniste</span>
          <Button
            variant="info"
            size="sm"
            className="w-100"
            onClick={() => calling()}
            disabled={loading}
          >
            <i className="fa fa-phone-alt me-2"></i>
            {loading ? (
              <strong>Appel en cours...</strong>
            ) : (
              <strong>Appeler</strong>
            )}
          </Button>
        </div>
      </div>
    </Card>
  );
}

export default Contact;
