import React, {useState, useEffect} from 'react';
import Graphes from '../graphe';
import Loading from '../../../components/Loading';
import { dailyData, monthlyData, yearlyData, onlineUsers } from '../../../../data/wifi';


const types = {
    'daily' : dailyData,
    'monthly' : monthlyData,
    'year' : yearlyData,
}

const defaultState = {
    loadingGraph : true,
    loadingUsers : true,
}

function StatistiquesUser(){
    const [state, setState] = useState(defaultState);
    const [data, setData] = useState();
    const [type, setType] = useState('daily');

    const handleChange = (e) => {
        setType(e.target.value);
    }

    const handleState = (name, value) => {
        setState((prevState) => ({
            ...prevState,
            [name] : value,
        }));
    }

    useEffect(() => {
        // fetching data
        setData({
            labels: types[type].labels,
            datasets: [
                {
                    title: types[type].title,
                    label: types[type].label,
                    data: types[type].data,
                    borderColor : types[type].borderColor,    
                    fill : types[type].fill,
                },
            ],
        })
        handleState('loadingGraph', false);

        // fetching online users
        setState((prevState) => ({
            ...prevState,
            onlineUsers : onlineUsers,
        }));
        handleState('loadingUsers', false);

    }, [type]);

    return (
        <div className='card card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer p-1 row'>

            {/* head */}
            <div className='row mt-4'>
                <div className='col-9'>
                    <h1 className='card-title'> { data?.datasets[0].title }</h1>
                </div> 
                <div className='col-3'>
                    {/* select journalier ou mensuel */}
                    <select className='form-select form-control' onChange={ (e) => handleChange(e)} >
                        <option value='daily'>Journalier</option>
                        <option value='monthly'>Mensuel</option>
                        <option value='year'>Annulle</option>
                    </select>
                </div>   
            </div>

            {/* body */}
            <div className='card-body row'>
            
                {/* graphes */}
                <div className='col-12'>
                    { !state.loadingGraph ? <Graphes type={'line'} data={data}/> : <Loading color='var(--secondary)'/> }
                </div>
            </div>

            {/* footer */}
            <div className='card-footer row border-0'>
                {/* total */}
                <div className='col-6'>
                    Total
                    <div className='row mt-2'>
                        <span className='col-4 fw-bold'>  Ajourd'hui </span>
                        <span className='col-4 '>  12 Go </span>
                        <span className='col-4 text-success fw-bold'>  - 3 % </span>
                    </div>
                    <div className='row mt-1'>
                        <span className='col-4 fw-bold'>  Ce mois </span>
                        <span className='col-4 '>  450 Go </span>
                        <span className='col-4 text-danger fw-bold'>  + 10 % </span>
                    </div>
                    <div className='row mt-1'>
                        <span className='col-4 fw-bold'>  Cette année </span>
                        <span className='col-4 '> 3450 Go </span>
                        <span className='col-4 text-yellow fw-bold'>  0 % </span>
                    </div>
                </div>
                {/* mean */}
                <div className='col-6 border-left'>
                    Moyenne
                    <div className='row mt-2'>
                        <span className='col-4 fw-bold'>  journalier </span>
                        <span className='col-4 '>  20 Go </span>
                    </div>
                    <div className='row mt-1'>
                        <span className='col-4 fw-bold'>  Mensuel </span>
                        <span className='col-4 '>  450 Go </span>
                    </div>
                    <div className='row mt-1'>
                        <span className='col-4 fw-bold'>  Annulle </span>
                        <span className='col-4 '> 5450 Go </span>
                    </div>
                </div>
            </div>   
        </div>
    );
}

export default StatistiquesUser;