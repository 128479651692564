import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { createRole } from '../../../services/UserService';
import { toast } from 'react-toastify';

function Addrole({ show, handeClose }) {
  const [role, setRole] = React.useState("");

  const create = () => {
    if(role) {
      createRole(role).then(() => {
        toast.success(`Le rôle ${role} a été ajouté`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });

        setRole("");
      });
    }
    else{
      toast.error(`Veuillez remplir le champ`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      })
    }
  }
  
  return (
    <Modal
      show={show}
      onHide={handeClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ajouter un rôle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Entrez le titre du rôle</h4>
        <div>
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder="Role"
              value={role}
              onChange={e => setRole(e.target.value)}
            />
            <small>De préférence, n'utilisez ni espace ni majuscule (Exemple : receptionist)</small>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={handeClose}>Fermer</Button>
        <Button variant='primary' onClick={create}>Enregistrer</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Addrole