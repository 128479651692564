// clients
import { ClientGuide } from './guide/clients/ClientGuide';
import { ClientAjoutGuide } from './guide/clients/ClientAjoutGuide';
import { ClientModificationGuide } from './guide/clients/ClientModificationGuide';
import { ClientInformationGuide } from './guide/clients/ClientInformationGuide';
import { ClientSesssionGuide } from './guide/clients/ClientSesssionGuide';

// chambres
import { ChambreGuide } from './guide/chambres/ChambreGuide';
import { ChambreAjoutGuide } from './guide/chambres/ChambreAjoutGuide';
import { ChambreEquipementAjoutGuide } from './guide/chambres/ChambreEquipementAjoutGuide';
import { ChambreIcon } from './guide/chambres/ChambreIcon';


export const clientsData = [
    {
        uri: "client",
        title: "Les comptes clients",
        description : " Voir les informations des comptes clients, les modifier et voir leurs sessions. ",
        guide: ClientGuide,
    },
    {
        uri: "client/ajout",
        title: "Ajouter un compte utilisateur",
        description : "Ici vous trouverez les étapes à suivres pour ajouter un nouveau compte utilisateur.",
        guide: ClientAjoutGuide,
    },

    {
        uri : "client/modification",
        title : "Modifier les informations d'un compte utilisateur",
        description : "Ici vous trouverez les étapes à suivres pour modifier les informations d'un compte utilisateur.",
        guide: ClientModificationGuide,
    },
    {
        uri : "client/information",
        title : "Informations d'un compte utilisateur",
        description : "Ici vous trouverez les informations d'un compte utilisateur.",
        guide: ClientInformationGuide,
    },
    {
        uri : "client/sessions",
        title : "Sessions d'un compte utilisateur",
        description : "Ici vous trouverez les sessions d'un compte utilisateur.",
        guide: ClientSesssionGuide,
    },
]


// chambres
export const chambresData = [
    {
        uri: "chambre",
        title: "Les chambres",
        description : " Voir les informations des chambres, les modifier et voir leurs équipements. ",
        guide: ChambreGuide,
    },
    {
        uri: "chambre/ajout",
        title: "Ajouter une chambre",
        description : "Ici vous trouverez les étapes à suivres pour ajouter une nouvelle chambre.",
        guide: ChambreAjoutGuide,
    },
    {
        uri: "chambre/equipement",
        title: "Ajouter un équipement à une chambre",
        description : "Ici vous trouverez les étapes à suivres pour ajouter un équipement à une chambre.",
        guide: ChambreEquipementAjoutGuide,
    },
    {
        uri: "chambre/icones",
        title: "Ce que vous devez savoir sur les icônes des chambres",
        description : "Ici vous trouverez les étapes à suivres pour changer l'icône d'une chambre.",
        guide: ChambreIcon,
    }
]

export const documenationData = {
    'client': clientsData,
    'chambre': chambresData,
}    