import React from "react";
import { Link } from "react-router-dom";

function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const BreadCrumb = ({ path_segment }) => {
    const principal_path_segment = "documentation";

    const segments = path_segment.split("/");

    return (
        <div className="row page-titles mx-0">
            <ol className="breadcrumb">
                <li className="breadcrumb-item active">
                    <Link to={`/${principal_path_segment}`}>
                        {capitalize(principal_path_segment)}
                    </Link>
                </li>
                {segments.map((segment, index) => (
                    <li className="breadcrumb-item" key={index}>
                        <Link to={`/${principal_path_segment}/${segments.slice(0, index + 1).join("/")}`}>
                            {capitalize(segment)}
                        </Link>
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default BreadCrumb;
