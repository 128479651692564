export const dailyData = {
    'title' : 'Consomation journalière',
    'labels' : [
        // january 2023 days
        '01/01/23','02/01/23', '03/01/23', '04/01/23', '05/01/23', '06/01/23', '07/01/23', '08/01/23', '09/01/23', '10/01/23', '11/01/23', '12/01/23', '13/01/23', '14/01/23', '15/01/23', '16/01/23', '17/01/23', '18/01/23', '19/01/23', '20/01/23', '21/01/23', '22/01/23', '23/01/23', '24/01/23', '25/01/23', '26/01/23', '27/01/23', '28/01/23', '29/01/23', '30/01/23', '31/01/23',

    ],
    'data' : [
        // random 31 data
        12, 2, 5, 78, 10, 55, 46, 13, 20, 34,
        12, 2, 5, 78, 10, 55, 46, 13, 20, 34,
        12, 2, 5, 78, 10, 55, 46, 13, 20, 34,
        5        
    ],
    
    'label' : 'Go ',
    'fill' : false,
    'backgroundColor' : 'rgba(75,192,192,0.4)',
    'borderColor' : 'rgba(75,192,192,1)',
}

export const monthlyData = {
    'title' : 'Consomation mensuelle',
    'labels' : [
        'Jan','Fev','Mar','Avr','Mai','Jun','Jul','Aou','Sep','Oct','Nov','Dec'
    ],
    'data' : [
        12, 2, 5, 78, 10, 55, 46, 13, 20, 34,10, 55,
    ],
    
    'label' : 'Go ',
    'fill' : false,
    'backgroundColor' : 'rgba(75,192,192,0.4)',
    'borderColor' : 'rgba(75,192,192,1)',
}

export const yearlyData = {
    'title' : 'Consomation annuelle',
    'labels' : [
        '2014','2015','2016','2017','2018','2019','2020','2021','2022', '2023'
    ],
    'data' : [
        78, 10, 55, 46,2, 5, 78,20, 34,10,
    ], 
    
    'label' : 'Go ',
    'fill' : false,
    'backgroundColor' : 'rgba(75,192,192,0.4)',
    'borderColor' : 'rgba(75,192,192,1)',
}


export const onlineUsers = [
    {
        'id' : '199',
        'consomation' : '1200 Go',
        'localisation' : 'Chambre 1',
        'firstname' : 'John',
        'lastname' : 'Doe',
        'ip' : '123.456.789',
    },
    {
        'id' : 2,
        'consomation' : '12 Go',
        'localisation' : 'Chambre 1',
        'firstname' : 'John',
        'lastname' : 'Doe',
        'ip' : '123.456.789',
    },
    {
        'id' : 3,
        'consomation' : '12 Go',
        'localisation' : 'Chambre 1',
        'firstname' : 'John',
        'lastname' : 'Doe',
        'ip' : '123.456.789',
    },
    {
        'id' : 4,
        'consomation' : '12 Go',
        'localisation' : 'Chambre 1',
        'firstname' : 'John',
        'lastname' : 'Doe',
        'ip' : '123.456.789',
    },
    {
        'id' : 5,
        'consomation' : '12 Go',
        'localisation' : 'Chambre 1',
        'firstname' : 'John',
        'lastname' : 'Doe',
        'ip' : '123.456.789',
    },
    {
        'id' : 6,
        'consomation' : '12 Go',
        'localisation' : 'Chambre 1',
        'firstname' : 'John',
        'lastname' : 'Doe',
        'ip' : '123.456.789',
    },
    {
        'id' : 7,
        'consomation' : '12 Go',
        'localisation' : 'Chambre 1',
        'firstname' : 'John',
        'lastname' : 'Doe',
        'ip' : '123.456.789',
    },
    {
        'id' : 8,
        'consomation' : '12 Go',
        'localisation' : 'Chambre 1',
        'firstname' : 'John',
        'lastname' : 'Doe',
        'ip' : '123.456.789',
    },
    {
        'id' : 9,
        'consomation' : '12 Go',
        'localisation' : 'Chambre 1',
        'firstname' : 'John',
        'lastname' : 'Doe',
        'ip' : '123.456.789',
    },
    {
        'id' : 10,
        'consomation' : '12 Go',
        'localisation' : 'Chambre 1',
        'firstname' : 'John',
        'lastname' : 'Doe',
        'ip' : '123.456.789',
    },
    {
        'id' :11,
        'consomation' : '12 Go',
        'localisation' : 'Chambre 1',
        'firstname' : 'John',
        'lastname' : 'Doe',
        'ip' : '123.456.789',
    },
    {
         'id' : 12,
         'consomation' : '12 Go',
         'localisation' : 'Chambre 1',
        'firstname' : 'John',
        'lastname' : 'Doe',
        'ip' : '123.456.789',
    },
    {
        'id' : 10,
        'consomation' : '12 Go',
        'localisation' : 'Chambre 1',
        'firstname' : 'John',
        'lastname' : 'Doe',
        'ip' : '123.456.789',
    },
    {
        'id' :11,
        'consomation' : '12 Go',
        'localisation' : 'Chambre 1',
        'firstname' : 'John',
        'lastname' : 'Doe',
        'ip' : '123.456.789',
    },
    {
         'id' : 12,
         'consomation' : '12 Go',
         'localisation' : 'Chambre 1',
        'firstname' : 'John',
        'lastname' : 'Doe',
        'ip' : '123.456.789',
    }

    
]


export const users = [
    // 15 fake users
    {
        id : 1,
        firstname : 'John',
        lastname : 'Doe',
        contact : '123456789',
        localisation : 'Chambre 120',
        date_activation : '2021-01-01',
        date_expiration : '2021-01-01',
        status : 0, // 0 = desactivé, 1 = activé, 2 = expiré, 3 = en attente
        date_creation : '2021-01-01 12:00:00',
        date_last_connection : '2021-01-01 12:00:00',
        consommation : 5,
    },
    {
        id : 2,
        firstname : 'John',
        lastname : 'Doe',
        contact : '123456789',
        localisation : 'Chambre 120',
        date_activation : '2021-01-01',
        date_expiration : '2021-01-01',
        status : 0, 
        date_creation : '2021-01-01 12:00:00',
        date_last_connection : '2021-01-01 12:00:00',
        consommation : 5,
    },
    {
        id : 3,
        firstname : 'John',
        lastname : 'Doe',
        contact : '123456789',
        localisation : 'Chambre 120',
        date_activation : '2021-01-01',
        date_expiration : '2021-01-01',
        status : 0, 
        date_creation : '2021-01-01 12:00:00',
        date_last_connection : '2021-01-01 12:00:00',
        consommation : 5,
    },
    // 12 others users
    {
        id : 4,
        firstname : 'John',
        lastname : 'Doe',
        contact : '123456789',
        localisation : 'Chambre 120',
        date_activation : '2021-01-01',
        date_expiration : '2021-01-01',
        status : 1,
        date_creation : '2021-01-01 12:00:00',
        date_last_connection : '2021-01-01 12:00:00',
        consommation : 5,
    },
    {
        id : 11,
        firstname : 'John',
        lastname : 'Doe',
        contact : '123456789',
        localisation : 'Chambre 120',
        date_activation : '2021-01-01',
        date_expiration : '2021-01-01',
        status : 2,
        date_creation : '2021-01-01 12:00:00',
        date_last_connection : '2021-01-01 12:00:00',
        consommation : 5,
    },
    {
        id : 12,
        firstname : 'John',
        lastname : 'Doe',
        contact : '123456789',
        localisation : 'Chambre 120',
        date_activation : '2021-01-01',
        date_expiration : '2021-01-01',
        status : 0, 
        date_creation : '2021-01-01 12:00:00',
        date_last_connection : '2021-01-01 12:00:00',
        consommation : 5,
    },
    {
        id : 13,
        firstname : 'John',
        lastname : 'Doe',
        contact : '123456789',
        localisation : 'Chambre 120',
        date_activation : '2021-01-01',
        date_expiration : '2021-01-01',
        status : 0, 
        date_creation : '2021-01-01 12:00:00',
        date_last_connection : '2021-01-01 12:00:00',
        consommation : 5,
    },
    {
        id : 14,
        firstname : 'John',
        lastname : 'Doe',
        contact : '123456789',
        localisation : 'Chambre 120',
        date_activation : '2021-01-01',
        date_expiration : '2021-01-01',
        status : 1,
        date_creation : '2021-01-01 12:00:00',
        date_last_connection : '2021-01-01 12:00:00',
        consommation : 5,
    },
    {
        id : 21,
        firstname : 'John',
        lastname : 'Doe',
        contact : '123456789',
        localisation : 'Chambre 120',
        date_activation : '2021-01-01',
        date_expiration : '2021-01-01',
        status : 2,
        date_creation : '2021-01-01 12:00:00',
        date_last_connection : '2021-01-01 12:00:00',
        consommation : 5,
    },
    {
        id : 22,
        firstname : 'John',
        lastname : 'Doe',
        contact : '123456789',
        localisation : 'Chambre 120',
        date_activation : '2021-01-01',
        date_expiration : '2021-01-01',
        status : 0, 
        date_creation : '2021-01-01 12:00:00',
        date_last_connection : '2021-01-01 12:00:00',
        consommation : 5,
    },
    {
        id : 23,
        firstname : 'John',
        lastname : 'Doe',
        contact : '123456789',
        localisation : 'Chambre 120',
        date_activation : '2021-01-01',
        date_expiration : '2021-01-01',
        status : 0, 
        date_creation : '2021-01-01 12:00:00',
        date_last_connection : '2021-01-01 12:00:00',
        consommation : 5,
    },
    {
        id : 14,
        firstname : 'John',
        lastname : 'Doe',
        contact : '123456789',
        localisation : 'Chambre 120',
        date_activation : '2021-01-01',
        date_expiration : '2021-01-01',
        status : 1,
        date_creation : '2021-01-01 12:00:00',
        date_last_connection : '2021-01-01 12:00:00',
        consommation : 5,
    },
    {
        id : 21,
        firstname : 'John',
        lastname : 'Doe',
        contact : '123456789',
        localisation : 'Chambre 120',
        date_activation : '2021-01-01',
        date_expiration : '2021-01-01',
        status : 2,
        date_creation : '2021-01-01 12:00:00',
        date_last_connection : '2021-01-01 12:00:00',
        consommation : 5,
    },
    {
        id : 22,
        firstname : 'John',
        lastname : 'Doe',
        contact : '123456789',
        localisation : 'Chambre 120',
        date_activation : '2021-01-01',
        date_expiration : '2021-01-01',
        status : 0, 
        date_creation : '2021-01-01 12:00:00',
        date_last_connection : '2021-01-01 12:00:00',
        consommation : 5,
    },
    {
        id : 23,
        firstname : 'John',
        lastname : 'Doe',
        contact : '123456789',
        localisation : 'Chambre 120',
        date_activation : '2021-01-01',
        date_expiration : '2021-01-01',
        status : 0, 
        date_creation : '2021-01-01 12:00:00',
        date_last_connection : '2021-01-01 12:00:00',
        consommation : 5,
    },
    {
        id : 24,
        firstname : 'monja',
        lastname : 'Doe',
        contact : '123456789',
        localisation : 'Chambre 120',
        date_activation : '2021-01-01',
        date_expiration : '2021-01-01',
        status : 1,
        date_creation : '2021-01-01 12:00:00',
        date_last_connection : '2021-01-01 12:00:00',
        consommation : 5,
    },
     
]


export const history = [
    {
        id : 24,
        firstname : 'monja',
        lastname : 'Doe',
        ip : '123.456.789',
        localisation : 'Chambre 120',
        date_action : '2021-01-01 12:00:00',
        action : 0, // 0 = rejoindre, 1 = quitter, 2 = desactiver, 3 = activer, 4 = expirer
    },
    {
        id : 1,
        firstname : 'monja',
        lastname : 'Doe',
        ip : '123.456.789',
        localisation : 'Chambre 120',
        date_action : '2021-01-01 12:00:00',
        action : 2,
    },
    {
        id : 2,
        firstname : 'monja',
        lastname : 'Doe',
        ip : '123.456.789',
        localisation : 'Chambre 120',
        date_action : '2021-01-01 12:00:00',
        action : 1,
    },
    {
        id : 3,
        firstname : 'monja',
        lastname : 'Doe',
        ip : '123.456.789',
        localisation : 'Chambre 120',
        date_action : '2021-01-01 12:00:00',
        action : 3,
    },
    {
        id : 4,
        firstname : 'monja',
        lastname : 'Doe',
        ip : '123.456.789',
        localisation : 'Chambre 120',
        date_action : '2021-01-01 12:00:00',
        action : 4,
    },
    {
        id : 5,
        firstname : 'monja',
        lastname : 'Doe',
        ip : '123.456.789',
        localisation : 'Chambre 120',
        date_action : '2021-01-01 12:00:00',
        action : 1,
    }
]