import React from "react";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
//
import { asset_img } from "../../../helpers/data";
import Loading from "../Loading";

import default_img from "../../../images/avatar.svg";
import { formatFullDate } from "../../../helpers/dates";

function List({
  chackboxFun,
  data,
  loading,
  onDelete,
  sort,
  checkouts_counts,
  currentPage,
  paginate,
}) {
  const deleteFun = (id) => {
    swal({
      title: "Êtes-vous sûr?",
      text: "Une fois supprimé, vous ne pourrez plus le récupérer!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        onDelete(id);
      }
    });
  };

  console.log(data)

  return (
    <div className="table-responsive">
      <div id="example2_wrapper" className="dataTables_wrapper no-footer">
        <table
          id="example2"
          className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
        >
          <thead>
            <tr role="row">
              <th className="sorting_asc bg-none">
                <div className="form-check  style-1">
                  <input
                    type="checkbox"
                    onClick={() => chackboxFun("all")}
                    className="form-check-input"
                    id="checkAll"
                    required=""
                  />
                </div>
              </th>
              <th className="sorting_asc">Client</th>
              <th className="sorting">Raison</th>
              <th className="sorting">Facture</th>
              <th className="sorting">Total TTC</th>
              <th className="">Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading && data ? (
              data.length > 0 ? (
                data.map((checkouts, i) => {
                  return checkouts?.checkouts?.map((checkout,index) => {
                    console.log(checkout)
                    return <tr key={`${checkout.reference}-${index}`} role="row" className="odd">
                    <td className="sorting_1">
                      <div className="form-check style-1">
                        <input
                          type="checkbox"
                          onClick={() => chackboxFun(checkout.id)}
                          className="form-check-input"
                          id={`check${checkout.id}`}
                          required=""
                        />
                      </div>
                    </td>
                    <td>
                      <div className="concierge-bx d-flex align-items-center">
                        <img
                          className="me-3 rounded"
                          src={
                            checkouts.client.photo
                              ? asset_img(checkouts.client.photo)
                              : default_img
                          }
                          alt=""
                          style={{ objectFit: "cover" }}
                        />
                        <div>
                          <h6 className="mb-0 fs-14">
                            {checkouts.client.fullname}
                          </h6>
                          <span className="text-primary fs-14">
                            #{checkouts.reference}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="sorting_1">
                      {checkout.raison}
                    </td>
                    <td className="sorting_1">{checkout.isFactured ? "Oui" : "Non"}</td>
                    <td className="sorting_1">{checkout.prixTotal} Ar</td>
                    <td className="sorting_1">
                      <div
                        className="d-flex"
                        style={{
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Link
                          key={checkout.id}
                          to={"checkout/show/" + checkout.reference}
                        >
                          <Button variant="secondary">
                            <i
                              style={{
                                marginLeft: "5px",
                              }}
                              className="fa fa-solid fa-eye"
                            ></i>
                          </Button>
                        </Link>
                        <Button  variant="danger">
                          <i
                            style={{
                              marginLeft: "5px",
                            }}
                            className="fa fa-solid fa-trash"
                          ></i>
                        </Button>
                      </div>
                    </td>
                  </tr>
                  })
                })
              ) : (
                <tr>
                  <td colSpan="6" className="text-center  fs-20">
                    Aucun checkout à afficher
                    <br />
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan="6" className="text-center  fs-20">
                  <Loading color="var(--secondary)" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {data.length ? (
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
            <div className="dataTables_info">
              Affichage de { 
                currentPage === 1 ? 1 : (currentPage - 1) * sort + 1
              } à { 
                currentPage === 1 ? sort : data.length === sort ? currentPage * sort : (currentPage - 1) * sort + data.length
              } sur {checkouts_counts} entrées
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers mb-0"
              id="example2_paginate"
            >
              {
                currentPage === 1 ? '' : 
                (
                  <>
                    <button
                    className="paginate_button previous disabled"
                    onClick={() => paginate(currentPage - 1)}
                    >
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                    </button>
                </>
              )
              }
              
                <span>
                    <div
                      className='paginate_button '
                    >
                      { currentPage }  
                    </div>
                </span>
                    
              {
                currentPage >= Math.ceil(checkouts_counts / sort) ? '' :
                (
                <>
                  <button
                    className="paginate_button next"
                    onClick= { ()=> paginate(currentPage + 1)}
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </button>
                </>
                )
              }

            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default List;
