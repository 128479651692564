import React from 'react';
import Loading from '../../../components/Loading';
import { Link } from "react-router-dom";
import { formatDateTime } from '../../../../helpers/dates';

// action 0 = rejoindre, 1 = quitter, 2 = desactiver, 3 = activer, 4 = expirer
const action = [
    {value : 0, text : 'Rejoindre', color : 'success', icon : 'fas fa-user-plus', pastTense : 'rejoint'},
    {value : 1, text : 'Quitter', color : 'danger', icon : 'fas fa-user-minus', pastTense : 'quitté'},
    {value : 2, text : 'Desactiver', color : 'danger', icon : 'fas fa-user-slash', pastTense : 'désactivé'},
    {value : 3, text : 'Activer', color : 'success', icon : 'fas fa-user-check', pastTense : 'activé'},
    {value : 4, text : 'Expirer', color : 'secondary', icon : 'fas fa-user-clock', pastTense : 'expiré'},

]

function HistoryWifiUser({history, loading}) {
    return (
        <div className="table-responsive">
            <div id="example2_wrapper" className="dataTables_wrapper no-footer">
                <table
                id="example2"
                className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
                >
                    <thead>
                        <tr role="row">
                            <th className="border-0"></th>
                            <th className="border-0"></th>
                            <th className="border-0"></th>
                            <th className="border-0"></th>
                            <th className="border-0"></th>
                            <th className="border-0"></th>
                            <th className="border-0"></th>
                            <th className="border-0"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ? <Loading color="var(--secondary)" /> :
                            (
                                history.map((item, index) => (
                                    history.length === 0 ? (
                                        <tr>
                                            <td colSpan="6" className="text-center  fs-20">
                                                Aucun utilisateur trouvé
                                                <br />
                                            </td>
                                        </tr>
                                    ) : (
                                            <tr
                                                role="row"
                                                className={`text-${action[item.action].color}`}
                                                key={index}
                                            >

                                                <td >
                                                    <i className={`${action[item.action].icon}`}></i>
                                                </td>
                                                <td >
                                                    <span> { formatDateTime(item.date_action) } </span>
                                                </td>

                                                <Link 
                                                    to={`/wifi/user/${item.id}`}
                                                    className={`text-${action[item.action].color}`}
                                                >
                                                    <td colSpan={6}>
                                                        
                                                            <span>
                                                                {
                                                                // verbre être ou avoir0
                                                                action[item.action].text === 'Rejoindre' || action[item.action].text === 'Quitter' ? 
                                                                    `${item.firstname} ${item.lastname } a ${action[item.action].pastTense} le wifi`
                                                                : 
                                                                    `${item.firstname} ${item.lastname } a été ${action[item.action].pastTense} du wifi`
                                                                }
                                                            </span>
                                                    </td>
                                                </Link>
                                            </tr>
                                        )
                                    )
                                )
                            )
                        }
                    </tbody>
                </table>    
            </div>
        </div>
    )
}

export default HistoryWifiUser;