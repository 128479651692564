import React, { useState, useEffect } from "react";
import LoginWifiUser from "./loginWifi";
import Loading from "../../components/Loading";
import { notifyError, notifySuccess } from "../../../helpers/notification";
import { loginToken, connect  } from "../../../services/wifiService";



export default function ChenkingWifi({ mac, ip, linkLogin, linkLoginOnly}) {
    const defaultState = {
        mac: mac,
        ip: ip,
        linkLogin: process.env.REACT_APP_MIKROTIK_URL + "login",
        linkLoginOnly: linkLoginOnly,
        redirectLink : 'https://www.google.com',
        token : '',
    } 
    const [data, setData] = useState();
    const [state, setState] = useState(defaultState);
    const [registered, setRegistered] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleState = (name, value) => {
        setState({ ...state, [name]: value });
    }

    const connection = () => {
        const data = {
            dst : state.linkLogin,
            popup : false,
            username : process.env.REACT_APP_MIKROTIK_USERNAME,
            password : process.env.REACT_APP_MIKROTIK_PASSWORD,
            mac : state.token,
            ip : state.ip,
        }
        
        connect(data)   
        .then((response) => {
            if(response.status === 202)
            {
                notifySuccess(response.data.message);
                console.log(response.data);
                
                window.location.href = state.redirectLink;
            }
            else
            {
                notifyError(response.data.message);
                console.log(response.data);
            }
        })
        .catch((error) => {
            notifyError("Erreur : " + error);
        })
        .finally(() => {
            setLoading(false);
        });

    }
    const tokenVerification = () => {
        if(state.token === undefined) {
            // verifying stepeh_hotel_data localstorage
            const stephen_hotel_data = localStorage.getItem("stephen_hotel_data");
            if(stephen_hotel_data !== null) {
                handleState("token", stephen_hotel_data);
                setRegistered(true);
            }
            setLoading(false);
            return false;
        }
        else {
            
            loginToken(state.token)
            .then((response) => {
                if(response.status === 202)
                {
                    setRegistered(true);
                    connection();
                }
                else
                {
                    setRegistered(false)
                }
            })
            .catch((error) => {
                notifyError("Erreur : " + error);
            })
            .finally(() => {
                setLoading(false);
            });

        }
    }

    
    
    useEffect(() => {
        setLoading(true);
        tokenVerification();
        
    }, [state.token]);

    return (
        <>

            {loading ? (<Loading color="var(--secondary)" />) 
            :
                !registered ? 
                    <div className="row h-100 mt-5">
                        <div className="col-12 justify-content-center align-items-center">
                            <LoginWifiUser />
                        </div>
                    </div>
                :
                <div className="container-fluid">
                    <div className="row mt-5 h-100">
                        <div className="col-12 text-center justify-content-center align-items-center">
                            <h3 className="mt-5 text-secondary">Vous êtes connectés</h3>
                            <h6>Redirection ...</h6>
                        </div>
                    </div>
                </div>
            }

        </>
    );
}


