import React, { useState } from "react";
import { getContacts } from "../../../../services/SessionService";
import Loading from "../../../components/Loading";
import { Alert, Col, Row } from "react-bootstrap";
import Contact from "./contact";
import Dial from "./dial";
import { DeclineCall, stopCall } from "../../../../services/CallService";

function Calls() {
  const [state, setState] = useState([]);
  const [user, setUser] = useState(null);
  const [calling, setCalling] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleData = () => {
    setLoading(true);
    getContacts()
      .then(({ data }) => {
        setState(data);
      })
      .finally(() => setLoading(false));
  };

  const startCall = (contact) => {
    setUser(contact);
  };

  const showModal = () => {
    setCalling(true);
  };

  const stopingCall = (id) => {
    if (id) {
      stopCall(id).then(() => {
        setCalling(false);
        setUser(null);
      });
    } else {
      setCalling(false);
      setUser(null);
    }
  };

  const cancelingCall = (id) => {
    DeclineCall(id).then(() => {
      setCalling(false);
      setUser(null);
    });
  };

  React.useEffect(() => {
    handleData();
  }, []);

  return (
    <div>
      <Dial
        show={calling}
        user={user}
        startCall={showModal}
        stopCall={stopingCall}
        cancelCall={cancelingCall}
      />
      <Row>
        {state.map((item, index) => (
          <Col key={index} sm={12} md={6} lg={4}>
            <Contact contact={item} callUser={startCall} show={calling} />
          </Col>
        ))}
        {!loading && !state.length ? (
          <Alert variant="outline-info">
            <i className="fa fa-info-circle me-2"></i>
            <span>Aucun contact disponible.</span>
          </Alert>
        ) : (
          <></>
        )}
        {loading ? <Loading color="var(--secondary)" /> : <></>}
      </Row>
    </div>
  );
}

export default Calls;
