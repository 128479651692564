import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import Loading from "../../../components/Loading";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import "./sectionWifi.css";
import CheckPermission from "../../../../templates/components/CheckPermission/CheckPermission";
import {
  generateWifiUser,
  getWifiUser,
} from "../../../../services/ClientService";

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

export default function SectionWifi({ data, reservation, handleHisto }) {
  const [idWifi, setIdWifi] = useState(null);
  const [dataWifi, setDataWifi] = useState({});
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [telechargement, setTelechargement] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);

  const sectionRef = useRef(null);

  const createUserWifi = () => {
    setGenerating(true);
    generateWifiUser(data.id)
      .then((response) => {
        setIdWifi(response.data);
      })
      .catch((error) => {
        toast.error(
          "Erreur lors de la génération du compte wifi",
          toastOptions
        );
      })
      .finally(() => {
        setGenerating(false);
      });
  };

  const getIdWifi = () => {
    setLoading(true);
    getWifiUser(data.id)
      .then((response) => {
        if (response.data && response.data.user) setIdWifi(response.data.user);
      })
      .catch((error) => {
        setError(true);
        setErrorMessage("Erreur lors de la récupération du compte wifi");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDownload = async () => {
    setTelechargement(true);

    try {
      if (!sectionRef.current) return;

      html2canvas(sectionRef.current, { allowTaint: true, useCORS: true }).then(
        (canvas) => {
          canvas.toBlob((blob) => {
            const imageUrl = URL.createObjectURL(blob);
            const fileName = `${dataWifi.name}.jpg`;

            setTelechargement(false);
            notifySuccess("Téléchargement réussi");
            saveAs(imageUrl, fileName);
          }, "image/jpeg");
        }
      );
    } catch (error) {
      setTelechargement(false);
      notifyError("Erreur lors du téléchargement");
    }
  };

  const hideOpenAssign = () => {
    setOpenAssign(false);
  };

  const notifyError = (message) => toast.error(message, toastOptions);
  const notifySuccess = (message) => toast.success(message, toastOptions);

  useEffect(() => {
    getIdWifi();
  }, []);

  return (
    <div className="col-12 shadow p-2 mb-2 mt-2">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="row">
            <div className="col-3 text-center align-items-center mt-2">
              <h3 className="fw-bold">Compte Wifi </h3>
            </div>
            <div className="col-9">
              <CheckPermission permission="create_wifi">
                {idWifi ? (
                  <></>
                ) : (
                  <Button
                    className="btn"
                    disabled={generating}
                    variant="secondary"
                    onClick={() => createUserWifi()}
                  >
                    {generating
                      ? "Traitement en cours..."
                      : "Générer un identifiant"}
                  </Button>
                )}
              </CheckPermission>
            </div>
          </div>
          {idWifi ? (
            <>
              <div ref={sectionRef} className="row">
                <div className="col-3">
                  <div className="row justify-content-center align-items-center">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/wifi/image-qr-code?password=${dataWifi.passwordWifi}&ssid=${dataWifi.ssid}`}
                      id="qrcode"
                      className="w-100 h-100"
                    />
                  </div>
                </div>

                <div className="col-9">
                  <div className={`row rounded`}>
                    <div className="col-10 d-flex flex-column  pt-2 pb-2">
                      <div>
                        <h4>📡 Bienvenue au WiFi du Stephen Hotel</h4>
                        <ol className="ol-wifi">
                          <li>
                            Connectez-vous au réseau "STEPHEN" en entrant les
                            informations ci-dessous ou scannez le code QR:
                            <ul>
                              <li>
                                <b>Nom wifi :</b> {"stephen"}
                              </li>
                              <li>
                                <b>Mot de passe :</b> Votre mot de passe wifi
                              </li>
                            </ul>
                          </li>
                          <li>
                            Pour accéder à Internet, ouvrez votre navigateur.
                          </li>
                          <li>
                            Si vous n'êtes pas redirigé automatiquement, allez
                            sur http://stephenhotel.net/login.
                          </li>
                          <li>
                            Utilisez les informations suivantes :
                            <ul>
                              <li>
                                <b>Login :</b> {idWifi.username}
                              </li>
                              <li>
                                <b>Mot de passe :</b> {idWifi.password}
                              </li>
                            </ul>
                          </li>
                        </ol>
                        <h4>
                          Merci et profitez de votre connexion ! <br />
                          <br />
                          {/* ✨ Stephen Hotel ✨ */}
                        </h4>

                        <h4>📡 Welcome to the WiFi of Stephen Hotel</h4>
                        <ol className="ol-wifi">
                          <li>
                            Connect to the "STEPHEN" network by entering the
                            information below or scan the QR code:
                            <ul>
                              <li>
                                <b>WiFi Name :</b> stephen
                              </li>
                              <li>
                                <b>Password :</b> Votre mot de passe wifi
                              </li>
                            </ul>
                          </li>
                          <li>To access the Internet, open your browser.</li>
                          <li>
                            If you are not redirected automatically, go to
                            http://stephenhotel.net/login.
                          </li>
                          <li>
                            Use the following information:
                            <ul>
                              <li>
                                <b>Login :</b> {idWifi.username}
                              </li>
                              <li>
                                <b>Password :</b> {idWifi.password}
                              </li>
                            </ul>
                          </li>
                        </ol>
                        <h4>
                          Thank you and enjoy your connection! <br />
                          <br />✨ Stephen Hotel ✨
                        </h4>
                      </div>

                      {/* <span className="mt-3"><b> Date de création : </b> {formatDateTime(dataWifi.created_at)}    </span>
                              <span className=""><b> Date d'expiration : </b>  {formatDateTime(dataWifi.date_expiration)} </span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100 h-75 mt-4 d-flex justify-content-center">
                <Button
                  className="btn w-25"
                  disabled={telechargement}
                  variant="secondary"
                  onClick={() => handleDownload()}
                >
                  {telechargement ? (
                    <>
                      {" "}
                      <i className="fa fa-download" aria-hidden="true"></i>En
                      cours...
                    </>
                  ) : (
                    <>
                      {" "}
                      <i className="fa fa-download" aria-hidden="true"></i>
                      Télécharger
                    </>
                  )}
                </Button>
              </div>
            </>
          ) : (
            <div className="alert alert-warning mt-2" role="alert">
              Aucun identifiant généré pour ce client
            </div>
          )}
        </>
      )}
    </div>
  );
}
