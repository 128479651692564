import React, { useReducer, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { DeclineCall, answerCall, stopCall } from "../services/CallService";
import { asset_img } from "../helpers/data";
import Echo from "laravel-echo";
import { peerData } from "../data/peerjs";
import Peer from "peerjs";

const defaultContext = {
  state: null,
  dispatch: () => null,
};

const CallContext = React.createContext(defaultContext);

const defaultState = {
  call_info: null,
  show_window: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "new_call":
      return { ...state, call_info: action.call, show_window: true };
      break;
    case "stop_call":
      return defaultState;
      break;
    case "cancel_call":
      return defaultState;
      break;
    case "answer_call":
      if (state && state.call_info && state.call_info.call) {
        answerCall(state.call_info.call.id).then(() => {
          navigator.mediaDevices
            .getUserMedia({ video: false, audio: true })
            .then(function (mediaStream) {
              const peer = new Peer(peerData);

              peer.on("open", () => {
                const call = peer.call(
                  state.call_info.call.caller_peerid,
                  mediaStream
                );

                call.on("stream", function (remoteStream) {
                  action.setAudio(remoteStream);
                });
              });

              peer.on("disconnected", (id) => {
                console.log("done?");
              });

              // const conn = peer.connect(state.call_info.call.caller_peerid);
            })
            .catch(function (error) {
              console.error("Failed to get user media:", error);
            });
        });
      }
      return state;
    default:
      return state;
      break;
  }
};

const CallProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const [caller, setCaller] = useState(null);
  const [stopping, setStopping] = useState(false);
  const [answered, setAnswered] = useState(false);
  const audioRef = useRef(null);

  const stopingCall = () => {
    setStopping(true);
    if (state.call_info && state.call_info.call) {
      stopCall(state.call_info.call.id)
        .then(() => {
          dispatch({
            type: "stop_call",
          });
        })
        .finally(() => setStopping(false));
    }
  };

  const cancelingCall = () => {
    if (state.call_info && state.call_info.call) {
      DeclineCall(state.call_info.call.id).then(() => {
        dispatch({
          type: "cancel_call",
        });
      });
    }
  };

  const setStream = (remoteStream) => {
    if (remoteStream) {
      audioRef.current.srcObject = remoteStream;
    }
  };

  const answerCall = () => {
    setAnswered(true);
    dispatch({ type: "answer_call", setAudio: (stream) => setStream(stream) });
  };

  React.useEffect(() => {
    const echo = new Echo({
      broadcaster: "pusher",
      key: process.env.REACT_APP_MIX_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_MIX_PUSHER_APP_CLUSTER,
      encrypted: true,
    });

    const channelCalling = echo.channel("calling");
    const channelCancelCall = echo.channel("cancel-call");
    const channelAcceptCall = echo.channel("answer-call");
    const channelStopCall = echo.channel("stop-call");
    const user = JSON.parse(localStorage.getItem("userDetails"));

    channelCalling
      .subscribed(() => {
        console.log("subscribed calling channel");
      })
      .listen(".new_call", (e) => {
        // console.log(user);
        if (e.called.id === user.id) {
          dispatch({
            type: "new_call",
            call: e,
          });
        }
      });

    channelCancelCall
      .subscribed(() => {
        console.log("subscribed cancel-call channel");
      })
      .listen(".cancel_call", (e) => {
        if (e.caller.id === user.id || e.called.id === user.id) {
          dispatch({
            type: "cancel_call",
          });
        }
      });

    channelAcceptCall
      .subscribed(() => {
        console.log("subscribed accept-call channel");
      })
      .listen(".answer_call", (e) => {
        if (e.caller.id === user.id || e.called.id === user.id) {
          dispatch({
            type: "answer_call",
            call: e,
            setAudio: (stream) => setStream(stream),
          });
        }
      });

    channelStopCall
      .subscribed(() => {
        console.log("subscribe stop-call channel");
      })
      .listen(".stop.call", (e) => {
        if (e.caller.id === user.id || e.called.id === user.id) {
          dispatch({
            type: "stop_call",
          });
        }
      });

    return () => {
      channelCalling.stopListening(".new_call");

      channelCancelCall.stopListening(".cancel_call");
      channelAcceptCall.stopListening(".answer_call");
      channelStopCall.stopListening(".stop.call");
    };
  }, []);

  React.useEffect(() => {
    if (state.call_info && state.call_info.caller) {
      const caller = state.call_info.caller;
      setCaller({
        name: caller.name ?? `${caller.lastname}`,
        photo: caller.photo ? asset_img(caller.photo) : "",
      });
    }
  }, [state]);

  return (
    <CallContext.Provider value={{ state, dispatch }}>
      <Modal show={state.show_window}>
        <Modal.Header>
          <Modal.Title>Appel entrant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <audio ref={audioRef} autoPlay />
          {caller ? (
            <div className="text-center">
              <img
                className="profil-call-img rounded-circle mb-3"
                src={caller.photo}
                alt=""
              />
              <h5 className="mb-3">{caller.name}</h5>
              {answered ? (
                <></>
              ) : (
                <p className="mb-3 text-lg-center">Appel en cours...</p>
              )}
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  variant="primary"
                  className="me-2"
                  onClick={!answered ? cancelingCall : stopingCall}
                  disabled={stopping}
                >
                  <i className="fa fa-phone-slash"></i>
                </Button>
                {!answered ? (
                  <Button variant="success" onClick={answerCall}>
                    <i className="fa fa-phone-alt"></i>
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <div className="text-center">Appel entrant...</div>
          )}
        </Modal.Body>
      </Modal>
      {children}
    </CallContext.Provider>
  );
};

const useCall = () => React.useContext(CallContext);

export { CallContext, CallProvider, useCall };
