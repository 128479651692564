import { Link } from 'react-router-dom';    

function OnlineUser({users}) {
    return (
        <div className="row " style={{ height : '250px'}}>        
            <div className="col-12">
                <span className='col-4 mt-3 fw-bold text-success'> 12 utilisateurs en ligne  </span>
                <div className="row border-bottom">
                    <span className='col-5'>  Utilisateur </span>
                    <span className='col-4'> IP </span>
                    <span className='col-3'>  </span>
                </div> 
                <div style={{ width: '100%',height : '200px', overflowX: 'hidden', overflowY:'scroll', wordBreak: 'break-all'}}>
                    {
                        users.map((user, index) => (
                            <Link to={ `/wifi/user/${user.id}`}  key={index}>
                                <div className="row">

                                    <span className='col-5 d-flex flex-column'
                                     style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                                     >  
                                        <span className='fw-bold'>{user.firstname} {user.lastname} </span>
                                        <span className='small'>{user.localisation} </span>
                                     </span>

                                    <span className='col-4'>  {user.ip} </span>

                                    <span className='col-3'>  {user.consomation} </span>
                                </div>
                            </Link>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default OnlineUser;