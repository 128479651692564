import React from "react";
import { Route } from "react-router-dom";
import Login from "./screens/login";
import Home from "./screens/home";
import { useClient } from "../../../context/ClientContext";

function Sessions() {
  const { state } = useClient();
  const routes = [
    // Login
    { url: "", component: Home },
  ];

  return (
    <>
      {routes.map((data, i) => (
        <Route
          key={i}
          exact
          path={`/session/${data.url}`}
          component={data.component}
        />
      ))}
    </>
  );
}

export default Sessions;
