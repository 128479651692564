function ArticleContent({title, paragraph, src}) {
    return(
        <>
            <section className="mt-5">
                <h5>{ title }</h5>
               
                    <p style={{
                        whiteSpace: 'pre-line'
                    }}
                    >
                        { paragraph }
                    </p>
                { src && (
                    <img className="img-fluid" src={src} alt="illustration"/>
                )}
            </section>
        </>
    )
}
export default ArticleContent;