import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { validateForm } from "../../../helpers/wifi";
import { createWifiUser } from "../../../services/wifiService";

const toastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  };

const datetimenow = new Date().toISOString().slice(0, 16);
const datetimenow15 = new Date(new Date().getTime() + 15 * 60000).toISOString().slice(0, 16);



function AddWifiAccount ({handleClose, open, data, reservation})
{
    const defaultState = {
      user : data?.id,
      date_activation: datetimenow,
      date_expiration: datetimenow15,
      localisation: reservation?.chambre_id,
    }

    const [state, setState] = useState(defaultState);
    const [loading, setLoading] = useState(false);
    let history = useHistory();
    const notifyError = (message) => toast.error(message, toastOptions);
    const notifySuccess = (message) => toast.success(message, toastOptions);

    const handleState = (name, value) =>
        setState((prevState) => ({
        ...prevState,
        [name]: value,
        }));
    
    const resetState = () => {
      setState(defaultState);
    };
    
    const handleFile = (e) => {
        setState((prevState) => ({
        ...prevState,
        photo: e.target.files[0],
        }));
    };

    const save = () => {
        if(!validateForm(state)){
            notifyError("Veuillez remplir tous les champs obligatoires");
            return;
        } 
        else {
          setLoading(true)
            createWifiUser(state)
              .then((response) => {
                if(response.status === 202 ){
                  resetState();
                  toast.success(`Le compte utilisateur a été entegistré`, toastOptions);
                  handleClose()
                  
                  window.location.reload();
                }
                else {
                  setLoading(false)
                  toast.error(response.data.message, toastOptions)
                  console.log(response.data);
                }
              })
              .catch((err) =>{
                toast.error(`Erreur pendant l'enregistrement `, toastOptions)
                setLoading(false)
            });

        }
      };  
    return (
    <Modal
      show={open}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ajouter un compte utilisateur wifi
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="mb-4">Entrez les informations sur l'utilisateur'</h4>
        <div>
          
          <div className="form-group mb-3">
            <label>Date d'activation*</label>
            <input
              type="datetime-local"
              className="form-control input-default mb-2"
              placeholder={"Date d'activation"}
              value={state.date_activation}
              onChange={(e) => handleState("date_activation", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Date d'éxpiration*</label>
            <input
              type="datetime-local"
              className="form-control input-default mb-2"
              placeholder={"Date d'éxpration'"}
              value={state.date_expiration}
              onChange={(e) => handleState("date_expiration", e.target.value)}
            />
          </div>

        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={handleClose}>
          Fermer
        </Button>
        <Button variant="secondary" onClick={save} disabled={loading}>
          Génerer
        </Button>
      </Modal.Footer>
    </Modal>
    )
}

export default AddWifiAccount;