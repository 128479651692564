import React, { useState, useRef, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import axiosInstance from "../../services/AxiosInstance";
import { storeNumber } from "../../services/CallService";
import { toast } from "react-toastify";
import { formatDateTime } from "../../helpers/dates";
import { saveAs } from 'file-saver';
import SectionSsid from "../components/wifi/SectionSsid";

const Dashboard = () => {
    const [numero, setNumero] = useState(null);
    const [numeroInput, setNumeroInput] = useState("");
    const [generating, setGenerating] = useState(false);
    const [data, setData] = useState({});
    const [telechargement, setTelechargement] = useState(false);


    const ToastOption = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    };

    const notifyError = (message) => toast.error(message, ToastOption);
    const notifySuccess = (message) => toast.success(message, ToastOption);
    useEffect(() => {
        axiosInstance.get("/call/number-whatsapp").then((response) => {
            setNumero(response.data.numero);
            setData(response.data.phone);
        });

    }, []);

    const submitForm = () => {
        setGenerating(true);
        storeNumber(numeroInput).then((response) => {
            setNumero(response.data.numero);
            setData(response.data.phone);
            setNumeroInput("");
            setGenerating(false);
            toast.success("Numéro enregistré avec succès", ToastOption);
        });
    };


    const handleDownload = async () => {
        setTelechargement(true);
        const imageUrl = `${process.env.REACT_APP_API_URL}/call/whatsapp?numero=${numero}`;

        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();

            // Obtenez le nom de fichier à partir de l'URL ou attribuez un nom statique
            const fileName = `Whatsapp-${numero}`;

            saveAs(blob, fileName);
            setTelechargement(false);
            notifySuccess("Téléchargement réussi");
        } catch (error) {
            setTelechargement(false);
            notifyError("Erreur lors du téléchargement");
        }
    };

    return (
        <>
            <div style={{ display: "flex" }}>
                <Card
                    style={{
                        width: "65%",
                        marginRight: "5%",
                    }}
                >
                    <Card.Header>
                        <h4>QR Code WhatsApp</h4>
                    </Card.Header>
                    <Card.Body>
                        {numero === null ?
                            <div>
                                <h5>Vous n'avez pas encore généré de QR Code</h5>
                                <p>Vous pouvez générer un nouveau QR Code en remplissant le formulaire à droite</p>
                            </div>
                            :
                            <div className="row">
                                <div className="col-4">
                                    <div className="row justify-content-center align-items-center">
                                        <img src={`${process.env.REACT_APP_API_URL}/call/whatsapp?numero=${numero}`} alt="QR Code" />
                                    </div>
                                </div>
                                <div className="col-8">
                                    <h5>Numéro WhatsApp</h5>
                                    <p>{numero}</p>
                                    <h5>Date de création</h5>
                                    <p>{formatDateTime(data?.created_at)}</p>
                                    <div className="w-50 h-75 mt-4">
                                        <Button className="btn w-25" disabled={telechargement} variant="secondary" onClick={() => handleDownload()}>
                                            {telechargement ? <i className="fa fa-download" aria-hidden="true"
                                            ></i> : <i className="fa fa-download" aria-hidden="true"
                                            ></i>}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }
                    </Card.Body>
                </Card>
                <Card
                    style={{
                        width: "30%",
                    }}
                >
                    <Card.Header>
                        <h4>Générer un nouveau</h4>
                    </Card.Header>
                    <Card.Body>

                        <div className="form-group">
                            <label htmlFor="numero" className="mb-3" >Numéro</label>
                            <input
                                type="text"
                                className="form-control mb-3"
                                id="numero"
                                placeholder="Numéro"
                                value={numeroInput}
                                onChange={(e) => setNumeroInput(e.target.value)}
                            />
                        </div>
                        {numeroInput === null || numeroInput === "" ?
                            <button onClick={() => submitForm()} disabled className="btn btn-warning">Générer</button>
                            :
                            <button onClick={() => submitForm()} className="btn btn-success">{generating ? "Chargement..." : "Générer"}</button>
                        }

                    </Card.Body>
                </Card>
            </div>
            <div style={{width: "100%"}}>
            <SectionSsid />
            </div>
        </>
    );
}

export default Dashboard;