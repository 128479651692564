import React from "react";

import profile from "../../../images/avatar_2.jpg";

function User() {
  const userDetails = localStorage.getItem("userDetails");
  const auth_user = JSON.parse(userDetails);

  return (
    <div className="dropdown header-profile2 ">
      <div className="header-info2 text-center">
        <img src={profile} alt="" />
        <div className="sidebar-info">
          {auth_user ? (
            <div>
              <h5 className="font-w500 mb-0">{auth_user.displayName}</h5>
              <span className="fs-12">{auth_user.email}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div>
          <a
            href="https://lcom-corp.com"
            target="_blank"
            className="btn btn-md text-secondary"
          >
            Support
          </a>
        </div>
      </div>
    </div>
  );
}

export default User;
