export function twoDigit(number){
  let n = number;
  let dec = n - Math.floor(number);
  n = n - dec;
  return ("0" + n).slice(-2) + dec.toString().substr(1);
}

export function formatPrices(x) {
  if (x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  }
  return "0";
}