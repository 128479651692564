import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { createRole } from '../../../services/UserService';
import { toast } from 'react-toastify';
import { createSsid } from '../../../services/wifiService';

function AddSsid({ show, handeClose, regetSsid }) {
  const[loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({
    ssid: "",
    password: ""
  });

  const handleSsid = (e) => {
    setData({
      ...data,
      ssid: e.target.value
    })
  }

    const handlePassword = (e) => {
    setData({
      ...data,
      password: e.target.value
    })
  }

  const create = () => {
    console.log(data);
    if(data.ssid != "" && data.password != "") {
        setLoading(true);
      createSsid(data).then(() => {
        toast.success(`Le ssid  a été ajouté`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });

        setLoading(false);

        setData({
            ssid: "",
            password: ""
        });

        regetSsid();
      });
    }
    else{
      toast.error(`Veuillez remplir les champ`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      })
    }
  }
  
  return (
    <Modal
      show={show}
      onHide={handeClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ajouter un SSID wifi
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Nom de Wifi</h4>
        <div>
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder="Stephen-Hotel-Wifi"
              value={data.ssid}
              onChange={handleSsid}
            />
          </div>
        </div>
        <h4>Mot de passe</h4>
        <div>
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder="stephen*2024"
              value={data.password}
              onChange={handlePassword}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={handeClose}>Fermer</Button>
        <Button variant='primary' onClick={create}>{loading ? "En cours...." : "Enregistrer"}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddSsid;