export function roleHasPermission(permission, role_permissions = []) {
  return (
    role_permissions.find((item) => item.id === permission.id) !== undefined
  );
}

export function validateForm(inputs) {
  for (const key in inputs) {
    if (Object.hasOwnProperty.call(inputs, key)) {
      const val = inputs[key];
      if (val === "" || val === null || val === undefined) return false;
    }
  }
  return true;
}

export function getRole(user) {
  return user.roles && user.roles.length ? user.roles[0] : null;
}
