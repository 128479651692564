import { createFormData } from "../helpers/services";
import axiosInstance from "./AxiosInstance";

export function getListCheckout() {
  return axiosInstance.get(`checkout/browse`);
}

export function getCheckoutCount() {
    return axiosInstance.get(`checkout/browse/count`);
}
export function getReservations() {
    return axiosInstance.get(`checkout/create`);
}

export function createCheckout(data) {
    // const formatDate = (date) => {
    //     const dat = new Date(date);
    //     return `${dat.getFullYear()}-${String(dat.getMonth() + 1).padStart(2, '0')}-${String(dat.getDate()).padStart(2, '0')}`;
    // };
    return axiosInstance.post(`checkout/store`, createFormData({ ...data/*, expiration_date: formatDate(data.expiration_date)})*/}));
}

export function deleteCheckout(id) {
    return axiosInstance.post(`checkout/delete/${id}`);
}

export function showCheckout(id) {
    return axiosInstance.get(`checkout/show/${id}`);
}