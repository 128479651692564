import React, {useState, useEffect } from 'react';
import { getUserWifi, getWifiUserCount } from '../../services/wifiService';
import { getPaginationData } from '../../services/PaginationService';
import HeaderWifi from './wifi/header';
import Statistiques from './wifi/statistiques';
import AddWifiAccount from './wifi/addWifiAccount';
import ListUsers from './wifi/listUsers';
import HistoryWifi from './wifi/historyWifi';
import { users, history } from '../../data/wifi';

function Wifi()
{
    const [datas, setDatas] = useState([]);
    const [usersCounts, setUsersCounts] = useState(0);
    const [openAdd, setOpenAdd] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] =useState([]);
    const sort = 10;
    
    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);

    const handleData = (page_number) => {
        setLoading(true);

        getPaginationData('wifi/user', page_number)
            .then(({ data }) => {
                if (data.data.length) {
                    setDatas(data.data);
                    setCurrentPage(page_number);
                } else {
                    setDatas([]);
                }
            })
            .finally(() => setLoading(false));

    }; 


    // use effect
    useEffect(() => {
        // handleData(currentPage)
        setLoading(true)
        getUserWifi().then((res)=> {
            setLoading(false);
            setUsers(res.data)
        })
    }, []);
    
    useEffect(() => {
        getWifiUserCount()
            .then((response) => {
                setUsersCounts(response.data.count);
        }
    );
    
    }, [usersCounts]);

    return (
        <>
            {/* <HeaderWifi handleOpen={handleOpenAdd}/>  
            <AddWifiAccount
                handleClose={handleCloseAdd}
                open={openAdd}
            />

            <Statistiques /> */}

            <p className='mt-5 mb-1'>
                <i className={`fas fa-users`}></i>
                <span className=""> Liste des utilisateurs</span>
            </p>
            <ListUsers
                data={users}
                loading={loading}
                sort={sort}
                users_counts={usersCounts}
                paginate={handleData}
                currentPage={currentPage}
            />
            
            {/* <div className='mt-5 mb-1 row'>
                <div className='col-8 d-flex flex-row align-items-center'>
                    <i className="fas fa-history"></i>             
                    <span className=""> Historique d'utilisation</span>
                </div>
                <div className='col-2'>
                    <select className='form-control'>
                        <option value={'todayHistory'}>Aujourd'hui</option>
                        <option value={'monthHistory'}>Ce mois ci</option>
                    </select>
                </div>
                <div className='col-2'>
                    <input 
                        type='date'
                        className='form-control'
                    />
                </div>
            </div> */}
            {/* <HistoryWifi
                history={history}
                loading={loading}
            /> */}
        </>
    );
}

export default Wifi;