import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
//
import { asset_img } from "../../../helpers/data";
import Loading from "../Loading";

import default_img from "../../../images/avatar.svg";
import { formatFullDate } from "../../../helpers/dates";

import { sendFacture } from "../../../services/Facture";

function List({
  chackboxFun,
  data,
  loading,
  sort,
  facture_counts,
  currentPage,
  paginate
}) {
  

  const handleSend = (facture_publication) => {
    sendFacture(facture_publication)
      .then( (response) => {
          console.log(response.status);
      });
  }
  

  return (
    <div className="table-responsive">
      <div id="example2_wrapper" className="dataTables_wrapper no-footer">
        <table
          id="example2"
          className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
        >
          <thead>
            <tr role="row">
              <th className="sorting_asc bg-none">
                <div className="form-check  style-1">
                  <input
                    type="checkbox"
                    onClick={() => chackboxFun("all")}
                    className="form-check-input"
                    id="checkAll"
                    required=""
                  />
                </div>
              </th>
              <th className="sorting_asc">Client</th>
              <th className="sorting">Date de publication</th>
              <th className="sorting">Total</th>
              <th className="sorting">Solde</th>
              <th className="">Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading && data ? (
              data.length > 0 ? (
                data.map((facture, i) => (
                  <tr key={i} role="row" className="odd">
                    <td className="sorting_1">
                      <div className="form-check style-1">
                        <input
                          type="checkbox"
                          onClick={() => chackboxFun(facture.id)}
                          className="form-check-input"
                          id={`check${facture.id}`}
                          required=""
                        />
                      </div>
                    </td>
                    <td>
                      <div className="concierge-bx d-flex align-items-center">
                        <img
                          className="me-3 rounded"
                          src={
                            facture.client.photo
                              ? asset_img(facture.client.photo)
                              : default_img
                          }
                          alt=""
                          style={{ objectFit: "cover" }}
                        />
                        <div>
                          <h6 className="mb-0 fs-14">
                            {facture.client.firstname} {facture.client.lastname}
                          </h6>
                          <span className="text-primary fs-14">
                            #{facture.client.ref}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="sorting_1">
                      {formatFullDate(facture.date_publication)}
                    </td>
                    <td className="sorting_1">{facture.total} Ar</td>
                    {facture.solde === 0 ? (
                      <td className="sorting_1">
                        <span
                          className="badge badge-success w-100"
                          style={{
                            padding: "20px",
                            borderRadius: "50px",
                            fontSize: "15px",
                          }}
                        >
                          <i
                            className="fa fa-solid fa-check"
                            style={{
                              marginRight: "5px",
                            }}
                          ></i>
                          Payé
                        </span>
                      </td>
                    ) : (
                      <td className="sorting_1">{facture.solde} Ar</td>
                    )}{" "}
                    <td className="sorting_1">
                      <div
                        className="d-flex"
                        style={{
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Button variant="danger" onClick={() => handleSend(facture.date_publication)}>
                          Envoyez{" "}
                          <i
                            style={{
                              marginLeft: "5px",
                            }}
                            className="fa fa-envelope "
                          ></i>
                        </Button>
                        <Link
                          key={facture.id}
                          to={"invoices/generate/" + facture.id}
                        >
                          <Button variant="secondary">
                            <i
                              style={{
                                marginLeft: "5px",
                              }}
                              className="fa fa-solid fa-eye"
                            ></i>
                          </Button>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center  fs-20">
                    Aucune facture à afficher
                    <br />
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan="6" className="text-center  fs-20">
                  <Loading color="var(--secondary)" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {data.length ? (
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
            <div className="dataTables_info">
              Affichage de { 
                currentPage === 1 ? 1 : (currentPage - 1) * sort + 1
              } à { 
                currentPage === 1 ? sort : data.length === sort ? currentPage * sort : (currentPage - 1) * sort + data.length
              } sur {facture_counts} entrées
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers mb-0"
              id="example2_paginate"
            >
              {
                currentPage === 1 ? '' : 
                (
                  <>
                    <button
                    className="paginate_button previous disabled"
                    onClick={() => paginate(currentPage - 1)}
                    >
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                    </button>
                </>
              )
              }
              
                <span>
                    <div
                      className='paginate_button '
                    >
                      { currentPage }  
                    </div>
                </span>
                    
              {
                currentPage >= Math.ceil(facture_counts / sort) ? '' :
                (
                <>
                  <button
                    className="paginate_button next"
                    onClick= { ()=> paginate(currentPage + 1)}
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </button>
                </>
                )
              }
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default List;
