import React from "react";
import { Container, Navbar } from "react-bootstrap";
import { useClient } from "../../../../context/ClientContext";

function SessionLayout({ children, title = "" }) {
  const { dispatch } = useClient();
  const user = JSON.parse(localStorage.getItem("clientDetails"));

  const logout = () => {
    dispatch({
      type: "logout",
      id: user.log,
    })
  }

  return (
    <Container>
      <Navbar>
        <Container>
          <Navbar.Brand href="#">Stephen Hotel</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            {user && user.client ? (
              <Navbar.Text style={{ cursor: "pointer" }} onClick={logout}>
                <strong className="text-secondary">
                  {`${user.client.lastname} ${user.client.firstname}`}
                </strong>
                <span className="ms-2">
                  <i className="fa fa-sign-out-alt"></i>
                </span>
              </Navbar.Text>
            ) : (
              <></>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <h2 className="mb-4">{title}</h2>
      {children}
    </Container>
  );
}

export default SessionLayout;
