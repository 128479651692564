import React from "react";
import { Modal, ModalBody } from "react-bootstrap";

import pic_default from "./../../../images/avatar.svg";
import { formatDateTime, formatFullDate } from "../../../helpers/dates";
import { Link } from "react-router-dom";
import { asset_img } from "../../../helpers/data";
import { formatPrices } from "../../../helpers/numbers";
import Loading from "../Loading";
import { getStatus } from "../../../helpers/reservations";

function Details({ open, data, close }) {
  const status = data? getStatus(data.status) : {};
  return (
    <Modal show={open} onHide={close} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Détails</Modal.Title>
      </Modal.Header>
      <ModalBody>
        {data ? (
          <div className="card-body">
            <div className="guest-profile">
              <div className="d-flex">
                <img
                  src={
                    data.client_photo
                      ? asset_img(data.client_photo)
                      : pic_default
                  }
                  alt=""
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
                <div>
                  <h2 className="font-w600">{data.client_name}</h2>
                  <span className="text-secondary">ID {data.client_ref}</span>
                  <span className="d-block mt-2 text-secondary">
                    Adresse : {data.adresse}
                  </span>
                  <div className="call d-flex align-items-center">
                    <a href={data.telephone ? `tel:${data.telephone}` : `#`}>
                      <i className="fas fa-phone-alt text-secondary"></i>
                    </a>
                    <button
                      className="btn btn-secondary ms-3"
                      onClick={() =>
                        window.open(`mailto:${data.client_email}`, "_blank")
                      }
                    >
                      <svg
                        className="me-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24.18"
                        viewBox="0 0 24 24.18"
                      >
                        <g
                          id="_032-speech-bubble"
                          data-name="032-speech-bubble"
                          transform="translate(-1.63 0)"
                        >
                          <g
                            id="Group_9"
                            data-name="Group 9"
                            transform="translate(1.63 0)"
                          >
                            <path
                              id="Path_118"
                              data-name="Path 118"
                              d="M22.193,3.6A12,12,0,0,0,1.636,12.361a11.434,11.434,0,0,0,.82,4.015,11.885,11.885,0,0,0,1.7,2.969l-.99,2.347a1.778,1.778,0,0,0,1.951,2.46l4.581-.792A12.013,12.013,0,0,0,22.193,3.6ZM12.749,16.8H9.61a.9.9,0,1,1,0-1.81h3.139a.911.911,0,0,1,.9.9A.893.893,0,0,1,12.749,16.8Zm4.892-3.676H9.61a.911.911,0,0,1-.9-.9.893.893,0,0,1,.9-.9h8.031a.9.9,0,1,1,0,1.81Zm0-3.7H9.61a.9.9,0,1,1,0-1.81h8.031a.911.911,0,0,1,.9.9A.93.93,0,0,1,17.641,9.421Z"
                              transform="translate(-1.63 0)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </svg>
                      Envoyer un mail
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="mt-4 check-status">
                  <span className="d-block mb-2">Arrivé</span>
                  <span className="font-w500 fs-16">
                    {formatDateTime(data.date_arrivee)}
                  </span>
                </div>
                <div className="mt-4">
                  <span className="d-block mb-2">Départ</span>
                  <span className="font-w500 fs-16">
                    {formatFullDate(data.date_depart)}
                  </span>
                </div>
                <div className="mt-4 ms-5">
                  <span className="d-block mb-2">Status</span>
                  <span className={`font-w500 fs-16 text-${status.color}`}>
                    <strong>{status.label}</strong>
                  </span>
                </div>
              </div>
            </div>
            <>
              <div className="d-flex flex-wrap">
                <div className="mt-4 check-status">
                  <span className="d-block mb-2">Informations</span>
                  <h4 className="font-w500 fs-24">{data.chambre_name}</h4>
                </div>
                <div className="mt-4 ms-3">
                  <span className="d-block mb-2 text-black">Prix</span>
                  <span className="font-w500 fs-24 text-black">
                    {formatPrices(data.chambre_prix)} Ar
                    <small className="fs-14 ms-2 text-secondary">/nuit</small>
                  </span>
                </div>
              </div>
              <p className="mt-2">{data.chambre_information}</p>
              <div className="facilities">
                <div className="mb-3 ">
                  <span className="d-block mb-3">Équipements</span>
                </div>
                {data.equipements?.map((equipment) => (
                  <Link
                    to={"#"}
                    className="btn btn-secondary light"
                    key={equipment.id}
                  >
                    {equipment.icon ? (
                      <i className={`${equipment.icon} me-2`}></i>
                    ) : (
                      <></>
                    )}

                    {equipment.name}
                  </Link>
                ))}
                {
                  data.equipements.length === 0 ? <span className="text-muted">Aucune équipement</span> : <></>
                }
              </div>
            </>
          </div>
        ) : (
          <Loading color="var(--primary)" />
        )}
      </ModalBody>
    </Modal>
  );
}

export default Details;
