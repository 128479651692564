import React, { useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Details from "../Orders/Details";
import { getOrder } from "../../../services/OrderService";
import CheckPermission from "../../../templates/components/CheckPermission/CheckPermission";

const Actions = ({ chambre, handleEdit, handleOpen, handleDelete }) => {
  return (
    <>
      <Dropdown className="dropdown">
        <Dropdown.Toggle as="div" className="btn-link i-false">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu">
          <Link className="dropdown-item" to={`/room/${chambre.id}`}>
            Détails
          </Link>
          <Link
            className="dropdown-item"
            to={`/orders/create?room=${chambre.id}`}
          >
            Réserver
          </Link>
          <CheckPermission permission="edit_room">
            <Dropdown.Item
              className="dropdown-item"
              onClick={() => {
                handleEdit(chambre);
                handleOpen();
              }}
            >
              Modifier
            </Dropdown.Item>
          </CheckPermission>
          <CheckPermission permission="delete_room">
          <Dropdown.Item 
            className="dropdown-item"
            onClick={() => {
              handleDelete(chambre.id);
            }}>Supprimer</Dropdown.Item>
          </CheckPermission>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export const DetailActions = ({ handleOpen, handleOpenEdit }) => {
  return (
    <>
      <Dropdown className="dropdown dropend ms-auto">
        <Dropdown.Toggle as="div" className="btn-link i-false">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
              stroke="#575757"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
              stroke="#575757"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
              stroke="#575757"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={handleOpen}>
            Ajouter un équipement
          </Dropdown.Item>
          <Dropdown.Item onClick={handleOpenEdit}>Modifier</Dropdown.Item>
          <Dropdown.Item>Supprimer</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export const ReservationActions = ({ id }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [details, setdetails] = useState(null);

  const closeDetails = () => setShowDetails(false);

  const handleDetail = (id) => {
    setShowDetails(true);
    getOrder(id).then(({ data }) => {
      setdetails(data);
    });
  };

  return (
    <>
      <Details data={details} open={showDetails} close={closeDetails} />
      <Button onClick={() => handleDetail(id)} variant="link">
        <i className="fa fa-eye me-2"></i>
        <span>Voir</span>
      </Button>
    </>
  );
};

export default Actions;
